<template>
 <f7-page class="grayback">
    <f7-navbar title="Absence Report Form" subtitle="All information is required" >
    </f7-navbar>

<f7-block v-if="!child.noheader" class="text-align-center">Given your answers, {{mystaffinfo?'you are ':(sutdentname+' is ')}}  not cleared to attend school. Please send this form.</f7-block>
  <f7-list class="potchild" media-list no-hairlines-md>
    <f7-list-item :header="!mystaffinfo?(`Grade/Level: ${child.info.gradmet}`):null" :title="sutdentname"><img src="../static/icons/avatar.png" slot="media" /></f7-list-item>

<f7-list-input
    v-if="mystaffinfo"
    label="Phone number"
    type="number"
    placeholder="Your mobile phone"
     :value="staffmobile"
    @input="staffmobile = $event.target.value"
    clear-button
    validate
    required
  >
       <f7-icon material="call" slot="media"></f7-icon>
  </f7-list-input>
  <f7-list-input
    label="Reason for absence"
    type="select"
    defaultValue=""
    placeholder="Please choose..."
      :value="reason"
    @input="reason = $event.target.value"
  >
    <f7-icon material="help_outline" slot="media"></f7-icon>
    <option value="symptoms_contacts">Symptoms / Contacts</option>
    <option value="illness">Illness</option>
    <option value="planned">Planned absence</option>
    <option value="other">Other</option>
  </f7-list-input>
<f7-list-input
    v-if="reason=='other'"
    label="Please specify"
    type="text"
    placeholder="reason for absence"
    clear-button
     :value="reason_detail"
    @input="reason_detail = $event.target.value"
  >
  </f7-list-input>
   <f7-list-input
    id="myDates"
    label="Absence Date(s)"
    type="datepicker"
    placeholder="Select date range"
    readonly
    @change="dates = $event.target.value"
    :calendar-params="{ dateFormat: 'mm/dd/yyyy', value:dates, rangePicker: true ,closeOnSelect :true}"
  >
      <f7-icon material="date_range" slot="media"></f7-icon>

  </f7-list-input>
   <f7-list-input
    label="Optional"
    type="textarea"
    placeholder="Type here comments or questions"
     :value="notes"
    @input="notes = $event.target.value"
  >
  </f7-list-input>
  </f7-list>
    <f7-block strong>
      <f7-row tag="p">
        <f7-button class="col" @click="reseter()" large raised>Cancel</f7-button>
        <f7-button class="col" @click="sendEmail()" :disabled="!canSubmit" large fill raised>Submit</f7-button>
      </f7-row>
    </f7-block>
  </f7-page>
</template>


<script>
import { mapState } from 'vuex';

export default {
  props:['child','myinfox'],
  data(){
    return {
      dates:[new Date()],
      reason:'symptoms_contacts',
      reason_detail:'',
      staffmobile:'',
      notes:''
    }
  },
     computed:{
       ...mapState(['settings']),
      canSubmit(){
        return this.reason && this.dates && ((this.reason=='other' && this.reason_detail) || this.reason!='other')
      },
      sutdentname(){
        return this.child.info.tsid?this.child.info.xnm:(this.child.info.fnm+' '+this.child.info.lnm)
      },
      mystaffinfo(){
        return this.myinfox || (this.child.tfid? this.child.info: null)
      }
  },
  mounted(){
    console.log('cksicl',this.child);
  },
  methods:{
    reseter(){
      this.notes='';
      this.dates= this.$moment().format("MM/DD/YYYY");
      this.$$('#myDates input[type="text"]').val(this.dates);
      this.reason='symptoms_contacts';
      this.reason_detail=''
      this.staffmobile='';
      this.$emit('cerrarmodal');
    },
      sendEmail() {
        let savedData={
          dates: this.dates,
          reason: this.reason,
          notes: this.notes,
          reason_detail: this.reason_detail,
          clp: this.$store.state.subdomain,
          symptoms: this.child.symptoms || [],
          exposure: this.child.exposure,
          staffmobile: this.staffmobile || null,
          info: this.child.info
        };
        console.log(savedData);
          this.$f7.dialog.preloader('Sending report...');
          let cualllamable=this.mystaffinfo?'neo_abrep_stafff':'neo_abrep';
           let unaabsserep = this.$firebase.functions().httpsCallable(cualllamable);
            unaabsserep(savedData).then((ladata)=> {
              console.log('llego data',ladata);
              let payload=ladata.data;
              if(payload.error){
                throw new Error(payload.error);
              }
              this.$emit('formok');
              this.$f7.dialog.close();
                this.$f7.dialog.alert('Thank you. You report has been sent. The form will close now.','Report Sent Succesfully',()=>{
                  this.reseter();
                });
            }).catch((error)=>{
              console.log('error en fx',error);
              this.$f7.dialog.close();
              this.$f7.dialog.alert(error.message,'Error');
            });
      }
  }
}
</script>
<style>
.potchild img{
  width: 30px;
  margin: 0 auto;
}
.potchild .icon{
  margin: 0 auto;
}
.grayback .page-content{
  background: #F1EFEF!important;
}
</style>