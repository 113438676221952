<template>
  <f7-page :page-content="false" @page:beforein="checktodaypasses()">
    <f7-navbar :title="settings.schoolname" subtitle="besafe.school">
      <f7-nav-right>
        <f7-link
          v-if="canscan"
          href="/scannerpre/"
          icon-material="aspect_ratio"
        ></f7-link>
        <f7-link v-if="!settings.unauth" panel-open="right" icon-only>
          <f7-icon
            :material="
              unreadnots ? 'notifications_active' : 'notifications_none'
            "
          >
            <f7-badge v-if="unreadnots" color="red">{{ unreadnots }}</f7-badge>
          </f7-icon>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-toolbar tabbar labels position="top">
      <f7-link
        tab-link="#dailycheck"
        tab-link-active
        text="Daily check"
        icon-material="playlist_add_check"
      ></f7-link>
      <f7-link
        tab-link="#absencesreps"
        text="Absences"
        icon-material="send"
      ></f7-link>
      <f7-link
        tab-link="#profileacct"
        text="Profile"
        icon-material="account_circle"
      ></f7-link>
    </f7-toolbar>
    <f7-tabs animated>
      <f7-tab id="dailycheck" class="page-content" tab-active>
        <f7-list v-if="!settings.unauth" class="no-margin" no-hairlines-md>
          <f7-list-input
            label="Select date"
            type="datepicker"
            @change="cambiandofecha($event.target.value)"
            :calendar-params="{
              dateFormat: 'mm/dd/yyyy',
              value: [today],
              closeOnSelect: true,
              disabled: {
                from: today,
              },
            }"
            readonly
          >
            <f7-icon class="fixcalicon" material="today" slot="media"></f7-icon>
          </f7-list-input>
        </f7-list>

        <template v-if="settings.imstaff">
          <f7-block-title class="boldertitl"
            >My self assessments</f7-block-title
          >
          <f7-block
            ><f7-button href="/daily_staff/" fill
              >Begin new self report</f7-button
            ></f7-block
          >
        </template>
        <template v-if="!offroles.parentoff">
          <f7-block class="no-margin-bottom margin-top">
            <f7-block-title class="boldertitl"
              >{{ forceManageChildren ? "Editing " : "My " }} children
              <f7-button
                outline
                v-if="hayChildren"
                @click="forceManageChildren = !forceManageChildren"
                class="float-right"
                >{{
                  forceManageChildren ? "Done" : "Add/Remove children"
                }}</f7-button
              ></f7-block-title
            >
            <p class="no-margin" v-if="!hayChildren">
              To complete a daily check, you must create at least one child
            </p>
          </f7-block>
          <f7-list class="margin-top-half no-margin-bottom" inset>
            <f7-list-item
              v-for="unchild in myvldchildrn"
              :key="unchild.sid"
              :title="unchild.fnm + ' ' + unchild.lnm"
              :header="`Grade/level: ${unchild.gradmet}`"
              ><f7-link
                slot="after"
                v-if="forceManageChildren"
                @click="predeltechild(unchild)"
                color="gray"
                icon-material="delete"
              ></f7-link
              ><f7-icon
                v-else-if="
                  alreadyOk[fechaselect] && alreadyOk[fechaselect][unchild.sid]
                "
                color="primary"
                material="event_available"
                slot="after"
              ></f7-icon
            ></f7-list-item>
            <f7-list-item
              v-for="unchild in self_def_stus"
              :key="unchild.tsid"
              :title="unchild.xnm"
              :footer="
                !settings.unauth
                  ? 'Pending validation by grade coordinator'
                  : null
              "
              :header="`Grade/level: ${unchild.gradmet}`"
              ><f7-link
                v-if="forceManageChildren"
                @click="predeltechild(unchild)"
                slot="after"
                color="gray"
                icon-material="delete"
              ></f7-link
              ><f7-icon
                v-else-if="
                  alreadyOk[fechaselect] && alreadyOk[fechaselect][unchild.tsid]
                "
                color="primary"
                material="event_available"
                slot="after"
              ></f7-icon
            ></f7-list-item>
          </f7-list>
          <template v-if="hayChildren && !forceManageChildren">
            <f7-block v-if="settings.unauth">
              <f7-button v-if="hayChildreSinPasses" href="/daily_students/" fill
                >Begin new daily check</f7-button
              >
              <f7-block-title class="text-color-purple text-align-center" v-else
                >All children have been cleared.</f7-block-title
              >
            </f7-block>
            <f7-block
              v-else-if="fechaselect == todaymom"
              class="text-align-center"
            >
              <f7-button href="/daily_students/" fill
                >Begin new daily check</f7-button
              >
            </f7-block>
          </template>

          <f7-block
            v-if="!hayChildren || forceManageChildren"
            class="text-align-center"
          >
            <template v-if="!settings.unauth">
              <f7-block-title>{{
                !hayChildren
                  ? settings.verifixts
                    ? "Link your account to a student via a CODE (provided by your school)"
                    : "Create child"
                  : "Add more children"
              }}</f7-block-title>
              <f7-row>
                <f7-col v-if="!settings.verifixts"
                  ><f7-button @click="opensheet({ coderedeem: false })" outline
                    >Manually</f7-button
                  ></f7-col
                >
                <f7-col
                  ><f7-button @click="opensheet({ coderedeem: true })" outline
                    >Redeem CODE</f7-button
                  ></f7-col
                >
              </f7-row>
            </template>
            <f7-button
              v-else
              @click="opensheet({ coderedeem: false })"
              outline
              >{{ !hayChildren ? "Create child" : "Add children" }}</f7-button
            >
          </f7-block>
        </template>

        <!-- Visitors daily check -->
        <template v-if="settings.visitors">
            <f7-block  class="text-align-center margin-top">
              <a @click="amIVisitor = !amIVisitor" class="am-i-visitor">
                <span v-if="!amIVisitor">Are you a visitor?</span>
                <span v-else>I'm not a visitor</span>
              </a>
            </f7-block>

            <f7-block v-if="amIVisitor">
              <f7-block-title class="boldertitl">Visitors</f7-block-title>
              <f7-button href="/daily_visitors/" fill
                >Begin new self report as visitor</f7-button
              >
            </f7-block>
          </template>

        <f7-block-title class="boldertitl"
          >My passes issued
          {{
            fechaselect == todaymom ? "today" : "on " + fechaselect
          }}</f7-block-title
        >
        <f7-block v-if="!todaypassess.length"
          >No passes generated for
          {{ fechaselect == todaymom ? "today" : fechaselect }}</f7-block
        >

        <f7-card v-for="unpass in todaypassess" :key="unpass.passid">
          <f7-card-content>
            <f7-list class="passcard" media-list>
              <f7-list-item divider :title="unpass.wh"></f7-list-item>
              <f7-list-item
                v-for="unstu in unpass.pld"
                :key="unstu.xisd"
                :header="
                  unstu.xtype == 'tfid'
                    ? settings.imstaff
                      ? settings.imstaff.fnm + ' ' + settings.imstaff.lnm
                      : 'Staff'
                  : unstu.xtype === 'vsid'
                    ? `${settings.imvisitor.fnm} ${settings.imvisitor.lnm}`
                    : `Grade/Level: ${unstu.gnam}`
                "
                :title="
                  unstu.xtype == 'sid' ? myvldchildrn[unstu.xisd] ? myvldchildrn[unstu.xisd].fnm + ' ' + myvldchildrn[unstu.xisd].lnm : null : self_def_stus[unstu.xisd] ? self_def_stus[unstu.xisd].xnm : null
                "
                ><img src="../static/icons/avatar.png" slot="media"
              /></f7-list-item>
            </f7-list>
          </f7-card-content>
          <f7-card-footer>
            <f7-link @click="sharethelink($event.target, unpass)"
              >Share link</f7-link
            >
            <f7-button @click="openQrViewer(unpass)" fill>View Pass</f7-button>
          </f7-card-footer>
        </f7-card>

        <f7-popover class="popover-menu popsharelink">
          <f7-list>
            <f7-list-item
              @click="openpromt('ph')"
              link="#"
              popover-close
              title="Share over sms"
            >
              <f7-icon material="textsms" slot="after"></f7-icon>
            </f7-list-item>
            <f7-list-item
              @click="openpromt('em')"
              link="#"
              popover-close
              title="Share over email"
            >
              <f7-icon material="email" slot="after"></f7-icon>
            </f7-list-item>
          </f7-list>
        </f7-popover>

        <f7-sheet
          class="demo-sheet"
          :opened="childCreationOpened"
          @sheet:closed="resetsheetx()"
        >
          <f7-toolbar>
            <div class="left">
              <f7-link color="red" sheet-close>Cancel</f7-link>
            </div>
            <div class="right">
              <f7-block
                ><f7-button fill @click="whatToDo()">{{
                  redeemerOpened ? "Redeem" : "Add Child"
                }}</f7-button></f7-block
              >
            </div>
          </f7-toolbar>
          <!-- Scrollable sheet content -->
          <f7-page-content class="fondogrisss">
            <f7-list
              v-if="!redeemerOpened"
              class="no-margin-top"
              no-hairlines-md
            >
              <f7-list-input
                label="Grade/Level"
                type="select"
                defaultValue=""
                :value="newchild.gid"
                @input="newchild.gid = $event.target.value"
                placeholder="Please choose..."
              >
                <option value="">Select</option>
                <option
                  v-for="grade in grades"
                  :key="grade.gid"
                  :value="grade.gid"
                  >{{ grade.nam }}</option
                >
              </f7-list-input>
              <f7-list-input
                label="Name"
                type="text"
                placeholder="Child name"
                :value="newchild.xnm"
                @input="newchild.xnm = $event.target.value"
                clear-button
              >
              </f7-list-input>
            </f7-list>
            <f7-block class="margin-top" v-else>
              <f7-list class="no-margin boradius">
                <f7-list-input
                  label="redeemable code"
                  class="kapitlz"
                  type="text"
                  placeholder="Type your code here"
                  :value="lecode"
                  @input="lecode = $event.target.value"
                  clear-button
                >
                </f7-list-input>
                <f7-block-footer class="text-align-center"
                  >In some institutions, you might be given a redeemable code
                  that verifies your role as a parent/tutor.</f7-block-footer
                >
              </f7-list>
            </f7-block>
          </f7-page-content>
        </f7-sheet>
      </f7-tab>
      <f7-tab
        id="absencesreps"
        @tab:show="checkpreviousabse()"
        class="page-content"
      >
        <f7-block class="text-align-center">
          <p>
            Please use the daily check flow for absences related to COVID
            symptoms or contacts.<br />Use this direct absence report form for
            other reasons.
          </p></f7-block
        >
        <template v-if="settings.unauth">
          <f7-block
            ><f7-button
              @click="opensheet({ coderedeem: false, directAbsence: true })"
              fill
              >Complete absence form</f7-button
            ></f7-block
          >
        </template>
        <template v-else>
          <f7-block v-if="settings.imstaff"
            ><f7-button @click="opendirectansecebystaff()" fill
              >Complete my absence form</f7-button
            ></f7-block
          >
          <template v-if="!offroles.parentoff">
            <f7-list>
              <f7-list-item
                divider
                :title="
                  hayChildren
                    ? 'Select a student'
                    : 'First, create children on the previous tab'
                "
              ></f7-list-item>
              <f7-list-item
                v-for="unchild in myvldchildrn"
                :checked="premanualabsece === unchild.sid"
                @change="premanualabsece = $event.target.value"
                radio
                name="preabsence"
                :value="unchild.sid"
                :key="unchild.sid"
                :title="unchild.fnm + ' ' + unchild.lnm"
                :header="`Grade/level: ${unchild.gradmet}`"
              ></f7-list-item>
              <f7-list-item
                v-for="unchild in self_def_stus"
                :checked="premanualabsece === unchild.tsid"
                @change="premanualabsece = $event.target.value"
                radio
                name="preabsence"
                :value="unchild.tsid"
                :key="unchild.tsid"
                :title="unchild.xnm"
                :footer="
                  !settings.unauth
                    ? 'Pending validation by grade coordinator'
                    : null
                "
                :header="`Grade/level: ${unchild.gradmet}`"
              ></f7-list-item>
            </f7-list>
            <f7-block
              ><f7-button
                @click="opendirectansece()"
                :class="{ disabled: !premanualabsece }"
                fill
                >Complete absence form</f7-button
              ></f7-block
            >
          </template>
          <f7-block-title>Submitted forms</f7-block-title>
          <f7-list media-list inset>
            <f7-list-item
              title="No forms submitted"
              v-if="!allrecords.length"
            ></f7-list-item>
            <f7-list-item
              v-for="oneabsform in allrecords"
              @click="abrepfocus = oneabsform"
              :key="oneabsform.key"
              :header="oneabsform.wno.gradmet"
              :title="oneabsform.nombre"
              :subtitle="'On ' + oneabsform.when"
              :footer="'By ' + (oneabsform.by || '-')"
              :after="oneabsform.reason_detail || oneabsform.reason"
              link="#"
            ></f7-list-item>
          </f7-list>

          <f7-popup
            class="abrepdetail"
            :opened="!!abrepfocus.wh"
            @popup:closed="abrepfocus = {}"
          >
            <f7-page v-if="abrepfocus.wh">
              <f7-navbar title="Absence Form" :subtitle="abrepfocus.when">
                <f7-nav-right>
                  <f7-link popup-close>Close</f7-link>
                </f7-nav-right>
              </f7-navbar>

              <f7-list media-list class="no-margin passcard">
                <f7-list-item
                  :title="abrepfocus.nombre"
                  :footer="'By ' + (abrepfocus.by || '-')"
                >
                  <img
                    src="../static/icons/avatar.png"
                    slot="media"
                    class="usricon"
                  />
                </f7-list-item>
                <f7-list-item
                  v-if="abrepfocus.xtyp == 'tfid'"
                  title="Phone"
                  :after="abrepfocus.staffmobile || '-'"
                ></f7-list-item>
                <f7-list-item divider title="Details"></f7-list-item>
                <f7-list-item
                  header="Dates"
                  :title="abrepfocus.dates"
                ></f7-list-item>
                <f7-list-item
                  header="Reason"
                  :title="abrepfocus.reason_detail || abrepfocus.reason"
                ></f7-list-item>
                <f7-list-item header="Notes">
                  <div class="absence-form-notes" slot="subtitle">
                    {{ abrepfocus.notes || "-" }}
                  </div>
                </f7-list-item>
                <f7-list-item
                  title="Contacts"
                  :after="abrepfocus.exposure || '-'"
                ></f7-list-item>
              </f7-list>
              <f7-block>
                <f7-block-title>Symptoms</f7-block-title>
                <f7-block v-if="abrepfocus.symptoms">
                  <f7-chip
                    v-for="unsyn in abrepfocus.symptoms"
                    :key="unsyn"
                    :text="unsyn"
                  ></f7-chip>
                </f7-block>
                <f7-block v-else>No symptoms reported</f7-block>
              </f7-block>
            </f7-page>
          </f7-popup>
        </template>
      </f7-tab>
      <f7-tab id="profileacct" class="page-content">
        <f7-list v-if="!settings.unauth" media-list class="no-margin">
          <f7-list-item
            header="account"
            :title="userinfo.email"
            :after="userinfo.emailVerified ? 'Verified' : 'Unverified'"
          ></f7-list-item>
          <f7-list-button
            v-if="!userinfo.emailVerified"
            title="Resend validation email"
            color="dark-green"
          ></f7-list-button>
        </f7-list>

        <f7-block-title class="margin-top"
          >Your roles in {{ settings.schoolname }}</f7-block-title
        >
        <f7-list inset>
          <f7-list-item
            v-if="settings.imstaff && !settings.nofaculty"
            footer="Send daily health checks and absence reports for yourself"
            header="Verified"
            title="Staff"
          >
          </f7-list-item>
          <f7-list-item
            footer="Send daily health checks and absence reports for your children/pupils"
            :header="
              !settings.unauth
                ? !offroles.parentoff
                  ? Object.keys(myvldchildrn).length
                    ? 'Verified'
                    : 'PENDING VALIDATION'
                  : 'disabled'
                : null
            "
            title="Parent/Tutor"
          >
            <f7-toggle
              v-if="!Object.keys(myvldchildrn).length && settings.imstaff"
              :checked="!offroles.parentoff"
              @toggle:change="cambiand('parentoff', $event)"
              slot="after"
            ></f7-toggle>
          </f7-list-item>
        </f7-list>

        <f7-block><br /></f7-block>
        <f7-block><br /></f7-block>
        <f7-block
          ><f7-button @click="logmeout()" outline>Logout</f7-button></f7-block
        >
      </f7-tab>
    </f7-tabs>

    <f7-popup
      class="demo-popup"
      :opened="absencePopupOpenede"
      @popup:closed="absencePopupOpenede = false"
    >
      <absence
        v-if="tempochildholder"
        :child="tempochildholder"
        @cerrarmodal="absencedone()"
      ></absence>
    </f7-popup>

    <f7-popup
      class="qr-popup"
      :opened="qrPopupMakerOpened"
      @popup:closed="qrdone()"
    >
      <passviewer v-if="passinfocus" :passportinfo="passinfocus"></passviewer>
    </f7-popup>
  </f7-page>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import absence from "../pages/absence";
import passviewer from "../pages/qrmaker";

import { load } from "recaptcha-v3";

export default {
  components: {
    absence,
    passviewer,
  },
  data() {
    return {
      fechaselect: this.$moment().format("MM/DD/YYYY"),
      redeemerOpened: false,
      absencePopupOpenede: false,
      passinfocus: null,
      qrPopupMakerOpened: false,
      allrecords: [],
      abrepfocus: {},
      tempochildholder: null,
      directAbsence: false,
      premanualabsece: null,
      today: new Date(),
      passbeingshared: {},
      todaymom: this.$moment().format("MM/DD/YYYY"),
      lecode: "",
      requestinpassess: false,
      offroles: {
        parentoff: false,
      },
      childCreationOpened: false,
      forceManageChildren: false,
      todaypassess: [],
      newchild: {
        gid: "",
        xnm: "",
      },

      amIVisitor: false
    };
  },
  computed: {
    ...mapState([
      "userinfo",
      "mysymptoms",
      "settings",
      "subdomain",
      "alreadyOk",
      "unreadnots",
    ]),
    hayChildren() {
      return (
        Object.keys(this.myvldchildrn).length ||
        Object.keys(this.self_def_stus).length
      );
    },
    myinfox() {
      return this.settings.imstaff;
    },
    canscan() {
      return this.settings.imstaff && this.settings.imstaff.skn;
    },
    hayChildreSinPasses() {
      let hay = false;
      if (this.fechaselect == this.todaymom) {
        //alreadyOk[fechaselect][unchild.tsid]
        let selfeftus = Object.keys(this.self_def_stus) || [];
        let vliaded = Object.keys(this.myvldchildrn) || [];
        let juntos = selfeftus.concat(vliaded);
        hay = juntos.some((childid) => {
          return (
            !this.alreadyOk[this.fechaselect] ||
            !this.alreadyOk[this.fechaselect][childid]
          );
        });
      }
      return hay;
    },
    ...mapGetters(["myvldchildrn", "self_def_stus"]),
    grades() {
      return this.settings.grades || [];
    },
  },
  created() {
    let offroles = this.settings.offroles || {};
    this.offroles.parentoff = offroles.parentoff || false;

    console.log("settings", this.settings);
  },
  methods: {
    checkpreviousabse() {
      if (!this.settings.unauth) {
        this.allrecords = [];
        this.$f7.dialog.preloader("Loading...");
        let selfeftus = Object.keys(this.self_def_stus) || [];
        let vliaded = Object.keys(this.myvldchildrn) || [];
        let juntos = selfeftus.concat(vliaded);
        let propormesas = [];
        juntos.forEach((unstuid) => {
          propormesas.push(
            this.$firebase
              .database()
              .ref("abrep/" + this.$store.getters.kore + "/bystu/" + unstuid)
              .once("value")
          );
        });
        if (this.myinfox) {
          propormesas.push(
            this.$firebase
              .database()
              .ref(
                "abrep/" +
                  this.$store.getters.kore +
                  "/bysta/" +
                  this.myinfox.tfid
              )
              .once("value")
          );
        }
        console.log("gettin previous abseces");
        let allrecords = [];
        return Promise.all(propormesas)
          .then((mulsi) => {
            mulsi.forEach((onsnap) => {
              if (onsnap.exists()) {
                let weono = onsnap.key;
                let woninfo =
                  this.myinfox && this.myinfox.tfid == weono
                    ? this.myinfox
                    : this.self_def_stus[weono] || this.myvldchildrn[weono];
                let allinfo = onsnap.val();
                let potsnao = Object.keys(allinfo);
                potsnao.forEach((unakey) => {
                  let unrecord = allinfo[unakey];
                  unrecord.wno = woninfo;
                  unrecord.key = unakey;
                  unrecord.nombre =
                    woninfo.xnm || woninfo.fnm + " " + woninfo.lnm;
                  unrecord.when = this.$moment.unix(unrecord.wh).format("LLL");
                  unrecord.by = unrecord.by
                    ? unrecord.by.split("|").join(".")
                    : null;
                  allrecords.push(unrecord);
                });
              }
            });
            allrecords.sort((a, b) => {
              return b.wh - a.wh;
            });
            console.log(allrecords, "allrecords");
            this.allrecords = allrecords;
            this.$f7.dialog.close();
          })
          .catch((error) => {
            this.$f7.dialog.close();
            console.log("error.", error);
            this.$f7.dialog.alert(error.message, error.code);
          });
      }
    },
    opendirectansecebystaff() {
      let childr = this.settings.imstaff;
      let namxsd = childr.fnm + " " + childr.lnm;
      let fakechil = {
        symptoms: [],
        tfid: true,
        noheader: true,
        noneofabove: false,
        exposure: null,
        report: null,
        nam: namxsd,
        info: childr,
      };
      this.tempochildholder = Object.assign({}, fakechil);
      this.absencePopupOpenede = true;
    },
    opendirectansece() {
      let childr =
        this.myvldchildrn[this.premanualabsece] ||
        this.self_def_stus[this.premanualabsece];
      let namxsd = childr.xnm || childr.fnm + " " + childr.lnm;
      let fakechil = {
        symptoms: [],
        noheader: true,
        noneofabove: false,
        exposure: null,
        report: null,
        nam: namxsd,
        info: childr,
      };
      this.tempochildholder = Object.assign({}, fakechil);
      this.absencePopupOpenede = true;
    },
    openQrViewer(passfocus) {
      this.passinfocus = Object.assign({}, passfocus);
      this.qrPopupMakerOpened = true;
    },
    qrdone() {
      this.qrPopupMakerOpened = false;
      this.passinfocus = null;
    },
    sharethelink(letarget, passbeingshared) {
      console.log("precomparitendo");
      this.passbeingshared = Object.assign({}, passbeingshared);
      this.$f7.popover.open(".popsharelink", letarget);
    },
    openpromt(medium) {
      let titl = {
        em: "Enter email",
        ph: "Enter mobile phone",
      };
      let frasex = {
        em: "only one email address",
        ph: "use a +1 format",
      };
      this.$f7.dialog.prompt(frasex[medium], titl[medium], (acual) => {
        console.log("number", acual, this.passbeingshared);
        this.sendshaer(medium, acual);
      });
    },
    sendshaer(medium, acual) {
      this.$f7.dialog.preloader("Sending...");
      let codeshare = this.$firebase.functions().httpsCallable("codeshare");
      return codeshare({
        domain: this.subdomain,
        pid: this.passbeingshared.passid,
        ymd: this.passbeingshared.date,
        via: medium,
        cual: acual,
      })
        .then((ladata) => {
          let linfo = ladata.data;
          if (linfo.error) {
            throw new Error(linfo.error);
          }
          this.$f7.dialog.close();
          let toastTop = this.$f7.toast.show({
            text: "Shared succesfully",
            position: "center",
            closeTimeout: 2000,
            destroyOnClose: true,
          });
        })
        .catch((error) => {
          this.$f7.dialog.close();
          console.log("error.", error);
          this.$f7.dialog.alert(error.message, error.code);
        });
    },
    checktodaypasses(cualfecha = null) {
      if (this.requestinpassess) {
        return false;
      }
      this.requestinpassess = true;
      if (!cualfecha) {
        cualfecha = this.$moment().format("YYYYMMDD");
      }
      this.$f7.dialog.preloader("Loading...");
      this.$firebase
        .database()
        .ref("repox/" + this.$store.getters.kore + "/" + cualfecha)
        .orderByChild("u")
        .equalTo(this.userinfo.uid)
        .once("value")
        .then((nsph) => {
          let mypassos = nsph.val() || {};
          console.log("mypasses", mypassos);
          let passeskyes = Object.keys(mypassos) || [];
          this.todaypassess = [];
          passeskyes.forEach((unpassskey) => {
            let passinfo = mypassos[unpassskey];
            let estePass = {
              wh: this.$moment.unix(passinfo.wh).format("LLL"),
              pld: passinfo.pld || {},
              passid: unpassskey,
              date: cualfecha,
            };
            //para avisar que ya estan completos
            //hay que transformar fecha a formato gringo
            let payloadx = {
              hoy: this.$moment(cualfecha, "YYYYMMDD").format("MM/DD/YYYY"),
              stu: passinfo.pld || {},
            };
            this.$store.commit("addOkToday", payloadx);
            this.todaypassess.push(estePass);
          });
          this.$f7.dialog.close();
          this.requestinpassess = false;
        })
        .catch((error) => {
          this.$f7.dialog.close();
          console.log("error.", error);
          this.$f7.dialog.alert(error.message, error.code);
          this.requestinpassess = false;
        });
    },
    absencedone() {
      this.absencePopupOpenede = false;
      this.tempochildholder = null;
      this.premanualabsece = null;
      if (!this.settings.unauth) {
        this.checkpreviousabse();
      }
    },
    resetsheetx() {
      this.childCreationOpened = false;
      this.lecode = "";
      this.newchild.xnm = "";
      this.newchild.gid = "";
    },
    opensheet(configs) {
      this.redeemerOpened = configs.coderedeem;
      (this.directAbsence = configs.directAbsence || false),
        (this.childCreationOpened = true);
    },
    whatToDo() {
      if (this.redeemerOpened) {
        this.canjea();
      } else {
        this.addingChild();
      }
    },
    cambiandofecha(valorx) {
      console.log("nueva fecha", valorx);
      this.fechaselect = valorx;
      this.checktodaypasses(
        this.$moment(valorx, "MM/DD/YYYY").format("YYYYMMDD")
      );
    },
    cambiand(cual, valor) {
      let turnoff;
      if (valor) {
        turnoff = false;
      } else {
        turnoff = true;
      }
      this.$f7.dialog.preloader("Updating...");
      this.$firebase
        .database()
        .ref("offroles/" + this.userinfo.uid + "/" + cual)
        .set(turnoff)
        .then(() => {
          this.$f7.dialog.close();
          this.offroles[cual] = turnoff;
        })
        .catch((error) => {
          this.$f7.dialog.close();
          console.log("error.", error);
          this.offroles[cual] = !turnoff;
          this.$f7.dialog.alert(error.message, error.code);
        });
    },
    predeltechild(elchild) {
      console.log("attempting to delete child", elchild);
      let warnging = elchild.tsid
        ? "Are you sure you want to remove " +
          elchild.xnm +
          " from your account?"
        : "Are you sure you want to unlink " +
          elchild.fnm +
          " from your account?";
      this.$f7.dialog.confirm(warnging, "Confirm", () => {
        this.$f7.dialog.preloader("Removing...");
        let removeself = this.$firebase.functions().httpsCallable("removeself");
        return removeself({
          domain: this.subdomain,
          tsid: elchild.tsid,
          sid: elchild.sid,
        })
          .then((ladata) => {
            let linfo = ladata.data;
            if (linfo.error) {
              throw new Error(linfo.error);
            }
            if (elchild.tsid) {
              this.$store.commit("removeSelfStu", elchild.tsid);
            } else if (elchild.sid) {
              this.$store.commit("removeCoreStu", elchild.sid);
            }
            this.forceManageChildren = false;
            this.$f7.dialog.close();
          })
          .catch((error) => {
            this.$f7.dialog.close();
            console.log("error.", error);
            this.$f7.dialog.alert(error.message, error.code);
          });
      });
    },
    addingChild() {
      //addself
      if (!this.newchild.xnm || !this.newchild.gid) {
        return this.$f7.dialog.alert(
          "You must specify a grade and a name",
          "Missing data"
        );
      } else {
        console.log(this.newchild);
        if (!this.settings.unauth) {
          this.$f7.dialog.preloader("Saving...");
          let addself = this.$firebase.functions().httpsCallable("addself");
          return addself({
            domain: this.subdomain,
            xnm: this.newchild.xnm.trim(),
            gid: this.newchild.gid,
          })
            .then((ladata) => {
              let linfo = ladata.data;
              if (linfo.error) {
                throw new Error(linfo.error);
              }
              let datous = linfo.info || {};
              console.log("proinfo info", datous);
              this.$store.commit("addSelfStu", datous);

              this.childCreationOpened = false;
              this.newchild.xnm = "";
              this.newchild.gid = "";
              this.forceManageChildren = false;
              this.$f7.dialog.close();
            })
            .catch((error) => {
              this.$f7.dialog.close();
              console.log("error.", error);
              this.$f7.dialog.alert(error.message, error.code);
            });
        } else {
          let tosv = {
            xnm: this.newchild.xnm.trim(),
            gid: this.newchild.gid,
            tsid: this.$moment().unix(),
            gradmet: this.$store.getters.onegrade(this.newchild.gid).nam,
          };

          if (this.directAbsence) {
            let fakechil = {
              symptoms: [],
              noheader: true,
              noneofabove: false,
              exposure: null,
              report: null,
              nam: tosv.xnm,
              info: tosv,
            };
            this.tempochildholder = Object.assign({}, fakechil);
            this.absencePopupOpenede = true;
          } else {
            this.$store.commit("addSelfStu", tosv);
          }
          this.childCreationOpened = false;
          this.newchild.xnm = "";
          this.newchild.gid = "";
          this.forceManageChildren = false;
        }
      }
    },
    canjea() {
      let coder = this.lecode || "";
      coder = coder.trim();
      if (!coder) {
        return this.$f7.dialog.alert("Code cannot be empty", "Oups...");
      } else {
        this.$f7.dialog.preloader("Validating...");
        return load("6Ld4FLcZAAAAAFKxu3sRtbF8QpfUPWEyvqfxpMa1")
          .then((recaptcha) => {
            return recaptcha.execute("redeem");
          })
          .then((token) => {
            console.log(token); // Will print the token
            let redeem = this.$firebase.functions().httpsCallable("redeem");
            return redeem({
              domain: this.subdomain,
              code: coder,
              recap: token,
            });
          })
          .then((ladata) => {
            let linfo = ladata.data;
            if (linfo.error) {
              throw new Error(linfo.error);
            }
            let datous = linfo.info || {};
            console.log("proinfo info", datous);
            //map el gid
            datous.gradmet = this.$store.getters.onegrade(datous.gid).nam;
            this.$store.commit("addStuAcctoun", datous);
            this.$f7.dialog.close();
            this.lecode = "";
            this.childCreationOpened = false;
            this.forceManageChildren = false;
          })
          .catch((error) => {
            console.log(error);
            this.$f7.dialog.close();
            this.$f7.dialog.alert(
              error && error.message ? error.message : error
            );
          });
      }
    },
    logmeout() {
      this.$f7.dialog.preloader("Clearing cache...");
      this.$firebase
        .auth()
        .signOut()
        .then(() => {
          console.log("Sign-out successful.");
          this.$store.commit("logmeout");
          this.$f7.dialog.close();
        })
        .catch((error) => {
          this.$f7.dialog.close();
          console.log("Sign-out error.", error);
          this.$f7.dialog.alert(error.message, error.code);
        });
    },
  },
};
</script>
<style>
.listinsetr {
  margin-left: calc(
    var(--f7-list-inset-side-margin) + var(--f7-safe-area-outer-left)
  ) !important;
  margin-right: calc(
    var(--f7-list-inset-side-margin) + var(--f7-safe-area-outer-right)
  ) !important;
}
.nowhitspae .item-title {
  white-space: unset !important;
}
.grisback .page-content {
  background: #e6e6e6;
}
.childcard img {
  width: 30px;
  margin: 0 auto;
}
.childcard .card-header {
  padding: 0px !important;
  display: block;
}

.accordion-item-opened .padforce {
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  background: #f0f3ff;
}
.kapitlz input {
  text-transform: uppercase !important;
}
.tab-link-active .tabbar-label {
  font-weight: bold;
}
.fixcalicon.icon {
  font-size: 35px;
  position: relative;
  top: -7px;
}
.fondogrisss {
  background: #dedede;
}
.list.boradius ul {
  border-radius: 12px;
}
.passcard img {
  width: 30px;
  margin: 0 auto;
}
.boldertitl.block-title {
  color: #366d50;
  font-weight: bold;
}
.absence-form-notes {
  overflow: hidden;
  white-space: normal;
}
.am-i-visitor {
  font-weight: bold;
  text-decoration: underline;
}
</style>