<template>
  <f7-page name="message" @page:beforein="callmessate()">
    <f7-navbar back-link="Back">
      <f7-nav-right>
        <f7-link icon-material="delete" @click="getdomainsatus()"></f7-link>
      </f7-nav-right>
    </f7-navbar>

<f7-block-title class="margin-top no-margin-bottom">Absence Report</f7-block-title>
<f7-block class="no-margin-top no-margin-bottom">
  <p>Sent on: {{$moment.unix(focusedMessage.wh).format('LLL')}}</p>
</f7-block>
<f7-block class="text-align-center" v-if="loaring">Loading message...
  <f7-block class="text-align-center">
      <f7-preloader color="multi"></f7-preloader>
    </f7-block>
</f7-block>
<template v-else>
  <f7-list media-list class="no-margin passcard">
      <template v-if="abrepfocus.stinf">
         <f7-list-item 
            :header="$store.getters.onegrade(abrepfocus.stinf.gid).nam"
            :title="abrepfocus.stinf.xnm"
            :footer="'By '+(abrepfocus.stinf.em.split('|').join('.') || '-')"
            >
            </f7-list-item>
      </template>
      <template v-else>
         <f7-list-item 
            :header="focusedMessage.type=='abrep_staff'?'Staff':focusedMessage.grnam"
            :title="focusedMessage.stunm"
            :footer="'By '+(abrepfocus.by.split('|').join('.') || '-')"
            >
            </f7-list-item>
            <f7-list-item v-if="abrepfocus.xtyp=='tfid' && abrepfocus.staffmobile && abrepfocus.staffmobile!='-'" @click="callnow(abrepfocus.staffmobile)" link="#" header="Phone" :title="abrepfocus.staffmobile"><f7-icon material="call" slot="after"></f7-icon></f7-list-item>
      </template>
            <f7-list-item divider title="Details"></f7-list-item>
            <f7-list-item header="Dates" :title="abrepfocus.dates"></f7-list-item>
            <f7-list-item header="Reason" :title="abrepfocus.reason_detail || abrepfocus.reason"></f7-list-item>
            <f7-list-item header="Notes" :subtitle="abrepfocus.notes || '-'"></f7-list-item>
            <f7-list-item title="Contacts" :after="abrepfocus.exposure || '-'"></f7-list-item>
          </f7-list>
          <f7-block>
            <f7-block-title>Symptoms</f7-block-title>
            <f7-block v-if="abrepfocus.symptoms">
               <f7-chip v-for="unsyn in abrepfocus.symptoms" :key="unsyn" :text="unsyn"></f7-chip>
            </f7-block>
            <f7-block v-else>No symptoms reported</f7-block>
          </f7-block>
  </template>
  </f7-page>
</template>
<script>
import {mapState, mapGetters} from 'vuex'
export default {
  data(){
    return {
      loaring: true,
      abrepfocus: {}
    }
  },
  computed: {
   ...mapState(['focusedMessage']),
   ...mapGetters(['kore'])
  
  },
  created(){
  
  },
  methods:{
     callnow(phonnum){
       if(phonnum && phonnum!='-'){
          document.location.href = "tel:"+phonnum;
       }
    },
    callmessate(){
      this.loaring= true;
      console.log('call',this.focusedMessage)
      let ref='';
      if(this.focusedMessage.type=='abrep'){
        ref='abrep/'+this.kore+'/bygr/'+this.focusedMessage.gid+'/'+this.focusedMessage.rut
      }
      else{
        ref=this.focusedMessage.rut
      }
      this.$firebase.database().ref(ref).once('value').then(snpshot=>{
        if(!snpshot.exists()){
          throw new Error('Form deleted')
        }
        let form = snpshot.val()
        console.log(form,'form')
        this.abrepfocus=Object.assign({},form)
        this.loaring= false
      }).catch(error=>{
        this.loaring= false
        console.log(error);
        this.$f7.dialog.alert(error.message,error.code)
      })
    }
  }
}
</script>