<template>
  <f7-app :params="f7params" >

 <!-- Right resizable Panel with Cover effect and dark layout theme -->
  <f7-panel  right @panel:open="triggerllamandoInfinito()">
    <f7-view url="/rightpanel/"></f7-view>
  </f7-panel>

  <!-- Your main view, should have "view-main" class -->
  <f7-view main class="safe-areas" url="/"></f7-view>

    <f7-login-screen id="my-login-screen" :opened="$store.state.loginAbierto">
      <f7-view>
        <f7-page login-screen>
          <f7-block class="text-align-center no-margin-bottom no-margin-top">
            <img class="defaultlogo" :src="deflogo" />
          </f7-block>
          <f7-login-screen-title>{{schoolname}}<div class="ultrasmall">Besafe V. {{$store.state.appVersion}}</div></f7-login-screen-title>

          <f7-block v-if="!loginwithcredentials">
              <f7-block-title class="text-align-center">Login</f7-block-title>
              <f7-button v-if="!unauth" @click="loginwithgoogle()" fill large raised>Login with Google</f7-button>
              <f7-button v-else @click="anologin()" :disabled="coreloading" fill large raised><f7-preloader v-if="coreloading" color="white"></f7-preloader><span v-else>Enter besafe.school</span></f7-button>
          </f7-block>
          <template v-if="!unauth">
          <f7-block v-if="!loginwithcredentials" class="text-align-center no-margin-bottom">
            <p>- or -</p>
            <f7-link @click="loginwithcredentials=true">Login using email and password</f7-link>
          </f7-block>
          <f7-block v-else-if="loginStep===1" class="text-align-center no-margin-bottom">
            <f7-link @click="loginwithcredentials=false">back to login with Google</f7-link>
          </f7-block>
          <template v-if="loginwithcredentials">
            <f7-list media-list class="logininputs no-margin-top">
            <f7-list-input v-if="loginStep===1" class="inputllogin" label="Enter your email" :value="email"  @keyup.native.enter="avanzaLogin()" type="email" @input="email=$event.target.value"></f7-list-input>
             <template v-else-if="loginStep===2">
              <f7-list-item class="titlebold" :title="email" header="Logging in as:">
                <f7-link class="lefarr" @click="loginStep=1" icon-material="arrow_back" slot="media"></f7-link>
              </f7-list-item>
              <f7-list-input class="inputllogin" label="Enter your besafe.school password"  @keyup.native.enter="ingresarConemailpass()" type="password" @input="password=$event.target.value">
                <div slot="media"></div>
              </f7-list-input>
            </template>
            <template v-else-if="loginStep===3">
              <f7-list-item class="titlebold" :title="email" header="Creating account with:">
                <f7-link class="lefarr" @click="loginStep=1" icon-material="arrow_back" slot="media"></f7-link>
              </f7-list-item>
              <f7-list-input class="inputllogin" label="Create a password for PREVENT"  @keyup.native.enter="creteAccount()" type="password" @input="password=$event.target.value">
                <div slot="media"></div>
              </f7-list-input>
            </template>
          </f7-list>
           <f7-block>
             <f7-row>
               <f7-col><f7-link @click="forgotMyPass()" v-if="loginStep===2">Forgot your password?</f7-link></f7-col>
              <f7-col>
                <f7-button v-if="loginStep===1" class="botbackwhite" @click="avanzaLogin()" fill raised>Next step</f7-button>
                <f7-button v-else-if="loginStep===2" class="botbackwhite" @click="ingresarConemailpass()" fill raised>Login</f7-button>
                <f7-button v-else-if="loginStep===3" class="botbackwhite" @click="creteAccount()" fill raised>Create Account</f7-button>
                </f7-col>
             </f7-row>
            </f7-block>
            </template>
          </template>

          <f7-list>
            <f7-block-footer>
             <span class="besafe"> besafe.school &copy; {{ $moment().year() }}</span><br><span class="hicapps">Powered by <f7-link target="_blank" href="https://hicapps.cl" external >HICAPPS</f7-link></span>
            </f7-block-footer>
          </f7-list>
        </f7-page>
      </f7-view>
    </f7-login-screen>

      <f7-popup class="qr-popup" :opened="qrvisoropened" @popup:closed="closeviewer()">
     <passviewer v-if="passforvisor" :passportinfo="passforvisor"></passviewer>
    </f7-popup>
  </f7-app>
</template>
<script>

  import routes from '../js/routes.js';
  import passviewer from '../pages/qrmaker';

  export default {
    components: {
       passviewer
    },
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          name: 'besafe', // App name
          theme: 'md', // Automatic theme detection
          dialog: {
            destroyOnClose: true,
          },


          // App routes
          routes: routes,
        },
        commentsRef: null,
        qrvisoropened: false,
        passforvisor: null,
        coreloading: true,
        deflogo: '../static/img/loading.png',
        schoolname: 'Loading...',
        unauth: true,
        email:'',
        password: '',
        loginwithcredentials: false,
        loginStep: 1
      }
    },
    computed:{
      misnotis(){
        return this.$store.state.notifications || []
      }
    },
    methods: {
      triggerllamandoInfinito(){
        this.$store.commit('setWhatTo',{what:'triggernotix', to: Date.now()})
      },
      anologin(){
        this.$f7.dialog.preloader('Accessing...');
        this.$firebase.auth().signInAnonymously().catch(function(error) {
            console.log(error)
            this.$f7.dialog.close();
            this.$f7.dialog.alert(error.message, error.code);
          });
      },
      loginwithgoogle(){
        this.$f7.dialog.preloader('Redirecting...');
        let provider = new this.$firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
          prompt: 'select_account'
        });
        this.$firebase.auth().signInWithRedirect(provider);
      },
      //manulal login fx
      forgotMyPass(){
        this.$f7.dialog.confirm('You will receive a password-reset link at '+this.email,  ()=> {
          this.$f7.dialog.preloader('Sending e-mail with link...');
              this.$firebase.auth().sendPasswordResetEmail(this.email).then(()=> {
                // Email sent.
                this.$f7.dialog.close();

                this.$f7.dialog.alert('Check your inbox at '+this.email, 'email sent');
              }).catch((error)=> {
                // An error happened.
                console.log(error)
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code);
              });
            });
      },
      emailIsValid (email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
      },
      avanzaLogin(){
        //chequear si este email existe
        this.email=this.email.trim();
        if(!this.email){
          return this.$f7.dialog.alert('You must provide an email','email missing');
        }
        if(!this.emailIsValid(this.email)){
          return this.$f7.dialog.alert('You must provide an email that looks like USER@DOMAIN.XYZ','email format invalid');
        }
        this.$f7.dialog.preloader('Verifying...');
        this.versiExisto();
      },
      versiExisto(){
        let doiexist = this.$firebase.functions().httpsCallable('doiexist');
        doiexist({email:this.email}).then((ladata)=> {
          this.$f7.dialog.close();
          if(ladata.data){
            //usuario ya existia, hay que pedirle su password
            this.loginStep=2;
          }
          else{
            //usuario no existia, pedirle que cree su password
            this.loginStep=3;
          }
          //this.$localforage.setItem('prevUser', this.elusuario);
        }).catch((error)=>{
          console.log('error en fx',error);
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,'Error');
        });
      },
        ingresarConemailpass(){
        if(!this.email || !this.password){
          return this.$f7.dialog.alert('You must provide a password');
        }
        this.$f7.dialog.preloader('Logging in...');
        return this.$firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(()=>{
          this.password=null;
        }).catch((error)=> {
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,error.code);
      });
      },
        creteAccount(){
        if(!this.email || !this.password){
          return this.$f7.dialog.alert('You must provide a password');
        }
        this.$f7.dialog.preloader('Creating...');
        this.$firebase.auth().createUserWithEmailAndPassword(this.email, this.password).catch((error)=> {
          this.$f7.dialog.close();
          console.log('preerror',error);
          if(error.code=='auth/email-already-in-use'){
            console.log('no pude crear, ntentando entrar');
            this.ingresarConemailpass();
          }
          else{
            this.$f7.dialog.alert(error.message,error.code);
          }
        });
      },
      //end manualogin,x
      getlogoname(subdomain){
         let landing = this.$firebase.functions().httpsCallable('landing');
            landing({domain:subdomain}).then((ladata)=> {
              let linfo = ladata.data;
              if(linfo.error){
                throw new Error(linfo.error);
              }
              let datous = linfo.info || {};
              console.log('proinfo info',datous);
            this.deflogo=datous.logo || '../static/img/default_logo.png';
            this.schoolname=datous.schoolname || 'School name';
            this.unauth = datous.unauth || false;
            this.coreloading=false;
        }).catch(error=>{
          this.deflogo='../static/img/default_logo.png';
          console.log('error reading logo',error);
          this.$f7.dialog.alert(error.message);
        });
      },
      closeviewer(){
        this.qrvisoropened= false;
        this.passforvisor=null;
      },
      checkparams(subdomain){
          let url_string = window.location.href
          let url = new URL(url_string);
          let d = url.searchParams.get("d");
          let r = url.searchParams.get("r");
          let q = url.searchParams.get("q") || null;
          if(d && r){
              //quiere mostrar
               this.$f7.dialog.preloader('Generating pass...');
                let getpass = this.$firebase.functions().httpsCallable('getpassnew');
                return getpass({domain: subdomain, pid: r, ymd: d, validate: q}).then((ladata)=> {
                  let linfo = ladata.data;
                  if(linfo.error){
                    throw new Error(linfo.error);
                  }
                  linfo.passid = r;
                  linfo.wh = this.$moment.unix(linfo.wh).format('LLL')
                  linfo.date= d
                  this.$f7.dialog.close();
                  if(q){
                    //quiere escanear
                    return this.$f7.dialog.alert('Valid. Reported on '+linfo.wh,'Valid Pass');
                  }
                  else{
                    this.passforvisor = Object.assign({},linfo);
                    this.qrvisoropened = true;
                  }
                  console.log(linfo);
                  window.history.replaceState(null, null, window.location.pathname);
                }).catch((error)=> {
                  this.$f7.dialog.close();
                  console.log('error.', error);
                  this.$f7.dialog.alert(error.message,error.code);
                });
          }
      }
    },
    mounted() {
      this.$f7ready((f7) => {
      let parts = location.hostname.split('.');
      let subdomain = parts.shift();
       if(subdomain=='localhost'){
          subdomain='test';
       }

        this.$store.commit('setWhatTo',{what:'subdomain',to:subdomain});


        this.$f7.dialog.preloader('Loading...');
        // Call F7 APIs here
        this.$firebase.auth().onAuthStateChanged((user)=> {
        if (user) {
            console.log(user);
            if(!this.unauth && user.isAnonymous){
               this.getlogoname(subdomain);
               this.$firebase.auth().signOut();
              this.$f7.dialog.alert('Anonymous login not allowed for this institution.','Flagged account');
            }
            else if(!this.unauth && !user.emailVerified){
            return user.sendEmailVerification().then(()=> {
              // Email sent.
              this.getlogoname(subdomain);
               this.$firebase.auth().signOut();
                 this.loginStep=1;
              this.$f7.dialog.alert('Check your inbox at '+user.email+' to validate your account before being able to login.','Validate');
            }).catch((error)=> {
              // An error happened.
               this.$f7.dialog.close();
               this.loginStep=1;
              this.$f7.dialog.alert(error.message,error.code);
            });
          }
          else{
            console.log('retrievent '+subdomain)
            let startup = this.$firebase.functions().httpsCallable('startupnew');
            startup({domain:subdomain}).then((ladata)=> {
            this.$f7.dialog.close();
            let preinf = ladata.data;
            if(preinf.error){
              throw new Error(preinf.error);
            }
            console.log('preinf',preinf);
            let datous = preinf.info || {};
            this.deflogo=datous.logo || '../static/img/default_logo.png';
            this.schoolname=datous.schoolname || 'School name';
              this.unauth = datous.unauth || false;
             this.coreloading=false;
                 let userinfo = {
                  displayName: user.displayName,
                  email: user.email,
                  emailVerified: user.emailVerified,
                  isAnonymous: user.isAnonymous,
                  phoneNumber: user.phoneNumber,
                  uid: user.uid
                };
                 this.$store.commit('setObjTo',{what:'settings',to:datous});
                  this.$store.commit('setObjTo',{what:'userinfo',to:userinfo});
                  this.$f7.views.main.router.navigate('/students/');
                  this.$f7.dialog.close();
                  this.$store.commit('setWhatTo',{what:'loginAbierto',to:false});
                   this.checkparams(subdomain);
                  //  if(user.email && user.emailVerified){
                  //     this.readMyNotifications(user.email);
                  //  }
              }).catch(error=>{
                console.log(error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message,error.code);
                if(error.message=='User email not verified'){
                  this.$firebase.auth().signOut();
                }
              })
          }
        } else {
          this.getlogoname(subdomain);
          console.log('no user logged in');
          this.$store.commit('setWhatTo',{what:'loginAbierto',to:true});
          this.$f7.dialog.close();
          //check if incoming url parametes
            this.checkparams(subdomain);
        }
      });
      });
    }
  }
</script>
<style>
.login-screen .page{
  background: url('../static/img/103612867_m.jpg');
  background-repeat:no-repeat;
  -webkit-background-size:cover;
  -moz-background-size:cover;
  -o-background-size:cover;
  background-size:cover;
  background-position:center;
}

.login-screen-content{
  background: unset;
}
.hicapps{
  font-style: italic;
    font-size: 12px;
}
.besafe{
  font-weight: bolder;
}
.ultrasmall{
  font-size:13px;
  color: #366c50;
}
.defaultlogo{
  max-width: 70vw;
  max-height: 125px;
}
.view-main .page-content{
  background: #F1EFEF!important;
}
.inputllogin input{
  background: #FFF!important;
    border-radius: 8px;
    padding-left: 5px!important;
    border: 1px solid #366c50;
}
.titlebold .item-title{
  font-weight: bold!important;
}
</style>