<template>
  <f7-page name="about">
    <f7-navbar title="Staff/Faculty Screening" subtitle="Section 2 of 2" back-link="Back"></f7-navbar>
    <f7-block strong>
      <p>{{Object.keys(contacts_question).length ? `2.a- ${contacts_question}` : "2.a- Have you been exposed to a household or close family member who has tested positive for or is suspected of having COVID-19 in the past 14 days?"}}</p>
    </f7-block>
      <f7-list>
      <f7-list-item
        radio
        name="staffradio1"
        value="yes"
        title="Yes"
        :checked="exposure === 'yes'"
        @change="$store.commit('setWhatTo',{what:'exposure',to:$event.target.value})"
      ></f7-list-item>
      <f7-list-item
        radio
        name="staffradio1"
        value="no"
        title="No"
        :checked="exposure === 'no'"
        @change="$store.commit('setWhatTo',{what:'exposure',to:$event.target.value})"

      ></f7-list-item>
   </f7-list>

     <f7-block strong>
      <p>2.b- Do you have a thermometer to take your temperature?</p>
    </f7-block>
    <f7-list>
      <f7-list-item
        radio
        name="stafftermometer"
        value="yes"
        title="Yes"
        :checked="thermometer === 'yes'"
        @change="$store.commit('setWhatTo',{what:'thermometer',to:$event.target.value})"
      ></f7-list-item>
      <f7-list-item
        radio
        name="stafftermometer"
        value="no"
        title="No"
        :checked="thermometer === 'no'"
        @change="$store.commit('setWhatTo',{what:'thermometer',to:$event.target.value})"

      ></f7-list-item>
     <f7-list-input
     v-if="thermometer=='yes'"
    label="temperature in degrees Fahrenheit"
    type="number"
    placeholder="temperature in degrees Fahrenheit"
     :value="temperature"
      @input="$store.commit('setWhatTo',{what:'temperature',to:$event.target.value})"
    clear-button
  >
    <f7-icon material="settings_remote" slot="media"></f7-icon>
  </f7-list-input>
    
    </f7-list>
    <f7-block><f7-button @click="sendForProcess()"  :disabled="!exposure || !thermometer || (thermometer=='yes' && !temperature)" large fill raised>Submit</f7-button></f7-block>
  </f7-page>
</template>
<script>
import { mapState, mapGetters } from 'vuex';

export default {
  data(){
    return{
     
    }
  },
       computed:{
      ...mapState(['exposure','mysymptoms','thermometer','temperature']),
      ...mapGetters(['contacts_question'])
	},
  methods:{
    sendForProcess(){
        //href="/students_final/"
        if(this.exposure=='no' && this.mysymptoms.length==0 && this.temperature && this.temperature<(100.4)){
          let mynewKey=this.$firebase.database().ref('reports/'+this.$store.state.subdomain+'/'+this.$firebase.auth().currentUser.uid).push().key;
            let tosave={
              exposure:this.exposure,
              temperature: this.temperature,
              type: 'staff',
              id: mynewKey,
              createdAt: this.$firebase.database.ServerValue.TIMESTAMP
            };
            this.$f7.dialog.preloader('Generating QR Code for display...');
            this.$firebase.database().ref('reports/'+this.$store.state.subdomain+'/'+this.$firebase.auth().currentUser.uid+'/'+mynewKey).set(tosave).then(()=>{
              this.$store.commit('setWhatTo',{what:'genKey',to:mynewKey});
                this.$f7.dialog.close();
                this.$f7.views.main.router.navigate('/students_final/');
            }).catch(error=>{
              this.$f7.dialog.close();
              this.$f7.dialog.alert(error.message, error.code);
            });
        }
        else if(this.mysymptoms.length || this.exposure=='yes' || (this.temperature && this.temperature>=(100.4))){
           this.$f7.dialog.alert('You are not cleared to attend school. Please complete the following Absence Report Form', 'Warning',()=>{
             this.$f7.views.main.router.navigate('/absence_staff/');
          });
        }
        else{
           let mynewKey=this.$firebase.database().ref('reports/'+this.$store.state.subdomain+'/'+this.$firebase.auth().currentUser.uid).push().key;
            let tosave={
              exposure:this.exposure,
              temperature: this.temperature,
              warning: true,
              type: 'staff',
              id: mynewKey,
              createdAt: this.$firebase.database.ServerValue.TIMESTAMP
            };
             
            this.$f7.dialog.preloader('Generating QR Code for display...');
            this.$firebase.database().ref('reports/'+this.$store.state.subdomain+'/'+this.$firebase.auth().currentUser.uid+'/'+mynewKey).set(tosave).then(()=>{
              this.$store.commit('setWhatTo',{what:'genKey',to:mynewKey});
              this.$store.commit('setWhatTo',{what:'warning',to:true});
                this.$f7.dialog.close();
                this.$f7.views.main.router.navigate('/students_final/');
            }).catch(error=>{
              this.$f7.dialog.close();
              this.$f7.dialog.alert(error.message, error.code);
            });
        }

    }
  }
}
</script>