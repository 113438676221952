<template>
    <f7-page class="grisback" name="daily_visitors">
        <!-- Top navbar -->
        <f7-navbar
            :title="$moment().format('dddd, LL')"
            subtitle="Complete your own information"
            back-link="Back"
        ></f7-navbar>

        <f7-block-title class="margin-top">My self report as visitor</f7-block-title>

        <f7-card class="childcard" :padding="false">
            <f7-card-header>
                <f7-list media-list>
                    <f7-list-item
                        :header="`Account: ${visitor ? visitor.leemail : 'Unregistered Visitor'}`"
                        :title="visitor ? `${visitor.fnm} ${visitor.lnm}` : `You are a new visitor`"
                        ><img src="../static/icons/avatar.png" slot="media"
                    /></f7-list-item>
                </f7-list>
            </f7-card-header>

            <f7-card-content>
                <f7-list media-list>
                    <!-- Visitor name -->
                    <f7-list-input
                        label="Name *"
                        type="text"
                        placeholder="Your name (required)"
                        clear-button
                        @input="name = $event.target.value"
                        :value="name"
                        required
                        validate
                    ></f7-list-input>

                    <!-- Reason -->
                    <f7-list-input
                        label="Reason"
                        type="textarea"
                        placeholder="Reason for your visit (optional)"
                        clear-button
                        @input="reason = $event.target.value"
                        :value="reason"
                        validate
                    ></f7-list-input>
                </f7-list>

                <f7-list v-if="isDataReady" accordion-list>
                    <f7-list-item class="sym_staff" accordion-item title="Symptoms">
                        <span slot="after">
                            <f7-icon
                                v-if="prokid.symptoms.length"
                                class="text-color-red"
                                material="warning"
                            ></f7-icon>
                            <f7-icon
                                v-else-if="prokid.noneofabove"
                                class="text-color-green"
                                material="check_circle_outline"
                            ></f7-icon
                            ><span v-else>Please answer</span>
                        </span>
                        <f7-accordion-content class="padforce">
                            <f7-block
                                >Today or since the last time you completed this screening, have
                                you experienced any of the following?
                            </f7-block>

                            <f7-block-title class="margin-top-half text-align-center">
                                Select all that apply
                            </f7-block-title>

                            <f7-list
                                inset
                                class="nowhitspae listinsetr bg-color-white fixcheckx"
                                :class="{ disabled: prokid.report }"
                            >
                                <f7-list-item
                                    checkbox
                                    v-for="chbox in sta"
                                    :key="chbox.i"
                                    name="my-symptoms"
                                    :value="chbox.i"
                                    :title="chbox.n"
                                    :checked="prokid.symptoms.includes(chbox.i)"
                                    @change="checksymptoms($event, prokid.sudoid)"
                                ></f7-list-item>

                                <f7-list-item divider title="or"></f7-list-item>

                                <f7-list-item
                                    checkbox
                                    name="my-symptoms-none"
                                    title="None of the above"
                                    :checked="prokid.noneofabove"
                                    @change="updateNoneOfAbove($event, prokid.sudoid)"
                                ></f7-list-item>
                            </f7-list>
                        </f7-accordion-content>
                    </f7-list-item>

                    <f7-list-item class="con_staff" accordion-item title="Contacts">
                        <span slot="after">
                            <f7-icon
                                v-if="prokid.exposure === 'yes'"
                                class="text-color-red"
                                material="warning"
                            ></f7-icon>
                            <f7-icon
                                v-else-if="prokid.exposure === 'no'"
                                class="text-color-green"
                                material="check_circle_outline"
                            ></f7-icon
                            ><span v-else>Please answer</span>
                        </span>

                        <f7-accordion-content class="padforce">
                            <f7-block>
                                <div class="pre">
                                {{
                                    Object.keys(contacts_question).length
                                    ? contacts_question
                                    : "Have you been exposed to a household or close family member who has tested positive for or is suspected of having COVID-19 in the past 14 days?"
                                }}
                                </div>
                            </f7-block>

                            <f7-list
                                inset
                                class="listinsetr bg-color-white margin-top"
                                :class="{ disabled: prokid.report }"
                            >
                                <f7-list-item
                                    radio
                                    name="radio"
                                    value="yes"
                                    title="Yes"
                                    :checked="prokid.exposure === 'yes'"
                                    @change="changeExposure(prokid.sudoid, 'yes')"
                                ></f7-list-item>
                                <f7-list-item
                                    radio
                                    name="radio"
                                    value="no"
                                    title="No"
                                    :checked="prokid.exposure === 'no'"
                                    @change="changeExposure(prokid.sudoid, 'no')"
                                ></f7-list-item>
                            </f7-list>
                        </f7-accordion-content>
                    </f7-list-item>
                </f7-list>
            </f7-card-content>

            <f7-card-footer>
                <p v-if="!allFieldsFilled">Please fill every field.</p>
                <p v-else-if="isEverythingOk">Cleared. Get pass.</p>

                <p v-else class="text-color-red">
                    <span>You are not cleared to attend the school as visitor.</span>
                    <span>Please contact the school at <b>{{ settings.contactEmail }}</b>.</span>
                </p>
            </f7-card-footer>

            <f7-button
                v-if="allFieldsFilled && isEverythingOk"
                href="#"
                large
                fill
                raised
                @click="getPass"
            >Get Your Pass</f7-button>
        </f7-card>
    </f7-page>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    data() {
        return {
            name: "",
            email: "",
            reason: "",

            recsymptoms: {},

            contactEmail: "",

            isDataReady: false
        };
    },

    computed: {
        ...mapState(["settings", "userinfo"]),

        ...mapGetters(["sta", "contacts_question"]),

        visitor() {
            return this.settings.imvisitor || null;
        },

        prokid() {
            return Object.values(this.recsymptoms)[0];
        },

        allFieldsFilled() {
            let trimmedName = this.name.trim();

            return (
                trimmedName.length &&
                (this.prokid.symptoms.length || this.prokid.noneofabove) &&
                this.prokid.exposure !== null
            );
        },

        isEverythingOk() {
            return (
                this.allFieldsFilled &&
                this.prokid.exposure === "no" &&
                !this.prokid.symptoms.length
            );
        }
    },

    mounted() {
        // visitor was previously created
        if (this.visitor) {
            this.name = `${this.visitor.fnm} ${this.visitor.lnm}`;
            this.email = this.visitor.vsid;
        }

        let neo = {};
        let cleanedEmail = this.userinfo.email.split('.').join('|').toLowerCase();

        neo[this.visitor ? this.visitor.vsid : cleanedEmail] = {
            symptoms: [],
            noneofabove: false,
            exposure: null,
            report: null,
            nam: this.visitor && this.visitor.fnm && this.visitor.lnm ? this.visitor.fnm + " " + this.visitor.lnm : "",
            info: this.visitor ? this.visitor : null,
            sudoid: this.visitor && this.visitor.vsid ? this.visitor.vsid : cleanedEmail,
        };

        this.recsymptoms = Object.assign({}, neo);

        this.isDataReady = true;
    },

    methods: {
        checksymptoms(event, xid) {
            let symptompid = event.target.value;

            if (event.target.checked) {
                this.recsymptoms[xid].noneofabove = false;

                if (!this.recsymptoms[xid].symptoms.includes(symptompid)) {
                    this.recsymptoms[xid].symptoms.push(symptompid);
                }
            } else {
                let indiexist = this.recsymptoms[xid].symptoms.findIndex((unitem) => unitem == symptompid);

                if (indiexist != -1) {
                    this.recsymptoms[xid].symptoms.splice(indiexist, 1);
                }
            }
        },

        updateNoneOfAbove(event, xid) {
            if (event.target.checked) {
                this.recsymptoms[xid].noneofabove = true;
                this.recsymptoms[xid].symptoms = [];
                this.$f7.accordion.close(".sym_staff");
            } else {
                this.recsymptoms[xid].noneofabove = false;
            }
        },

        changeExposure(xid, value) {
            this.recsymptoms[xid].exposure = value;
            this.$f7.accordion.close(".con_staff");
        },

        getPass() {
            this.$f7.dialog.preloader("Generating QR Code for display...");

            let splittedName = "";
            let lastName = "";
            let firstName = "";
            let email = this.userinfo.email;
            let cleanedEmail = email.split('.').join('|').toLowerCase();
            let newVisitor = {};
            let visitData = {};

            let wasPreviouslyCreated = this.visitor ? true : false;
            let didChangeName = false;

            // if visitor was not previously registered
            if (!wasPreviouslyCreated) {
                splittedName = this.name.trim().split(" ");
                lastName = splittedName.pop();
                firstName = splittedName.join(" ");

                newVisitor = {
                    fnm: firstName.trim(),
                    lnm: lastName.trim(),
                    vsid: cleanedEmail,
                    leemail: email.toLowerCase(),
                    linked: this.$moment().unix()
                };
            } else {
                let originalName = `${this.visitor.fnm} ${this.visitor.lnm}`;

                if (originalName.trim() !== this.name.trim()) {
                    splittedName = this.name.trim().split(" ");
                    lastName = splittedName.pop();
                    firstName = splittedName.join(" ");

                    didChangeName = true;
                }
            }

            let dailyCheckVisitor = this.$firebase.functions().httpsCallable("dailyCheckVisitor");

            dailyCheckVisitor({
                wasPreviouslyCreated: wasPreviouslyCreated,
                newVisitor: newVisitor,
                newFirstName: firstName,
                newLastName: lastName,
                reason: this.reason.trim(),
                subdomain: this.$store.state.subdomain,
                vsid: cleanedEmail
            })
            .then((response) => {
                console.log("response", response)
                let payload = response.data;
                if (payload.error) {
                    throw new Error(payload.error);
                }

                // set imvisitor in store
                if (!this.settings.imvisitor) {
                    this.$store.commit("setImVisitor", newVisitor);
                } else if (didChangeName) {
                    this.$store.commit("updateImVisitorName", {
                        fnm: firstName,
                        lnm: lastName
                    });
                }

                this.$f7.views.main.router.back();
                this.$f7.dialog.close();
            })
            .catch((error) => {
                console.error("error en fx", error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, "Error");
            });
        },
    }
}
</script>