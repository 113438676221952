<template>
  <f7-page class="svgris" name="scanner">
     <f7-navbar title="QR Scanner">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

<qrcode-stream @init="onInit" @decode="onDecode"></qrcode-stream>
  </f7-page>
</template>

<script>
import { QrcodeStream, QrcodeCapture } from 'vue-qrcode-reader'

import {mapState, mapGetters} from 'vuex';

export default {
   components: {
    QrcodeStream,
    QrcodeCapture
  },
  data() {
    return {
      potUser:null,
      notaPerso:''
    }
  },
  computed:{
    // ...mapState(['defaultAvatar','persona']),
    // ...mapGetters(['focusedEvent','myName','Myeventos']),

  },
  methods:{
     onDecode (decodedString) {
        this.$f7.dialog.close();
     console.log(decodedString);
          let url_string = decodedString
          let url = new URL(url_string);
          let d = url.searchParams.get("d");
          let r = url.searchParams.get("r");
          let q = url.searchParams.get("q") || null;
          if(d && r){
              //quiere mostrar
               this.$f7.dialog.preloader('Validating pass...');
                let getpass = this.$firebase.functions().httpsCallable('getpassnew');
                return getpass({domain: this.$store.state.subdomain, pid: r, ymd: d, validate: true}).then((ladata)=> {
                  let linfo = ladata.data;
                  if(linfo.error){
                    throw new Error(linfo.error);
                  }
                  linfo.passid = r;
                  this.$f7.dialog.close();
                  console.log(linfo);
                  this.$emit('newscan',linfo);
                 }).catch((error)=> {
                  this.$f7.dialog.close();
                  console.log('error.', error);
                  this.$f7.dialog.alert(error.message,error.code);
                });
          }
      },
   onInit (promise) {
    // show loading indicator
    this.$f7.dialog.preloader('Starting scanner...');
    return promise.then(()=>{
       console.log('Inicializado OK');
       this.$f7.dialog.close();
    }).catch(error=>{
      this.$f7.dialog.close();
      let errorreaseon='';
         if (error.name === 'NotAllowedError') {
        errorreaseon='user denied camera access permisson';
      } else if (error.name === 'NotFoundError') {
         errorreaseon=' no suitable camera device installed';
      } else if (error.name === 'NotSupportedError') {
         errorreaseon=' page is not served over HTTPS (or localhost)';
      } else if (error.name === 'NotReadableError') {
         errorreaseon=' maybe camera is already in use';
      } else if (error.name === 'OverconstrainedError') {
         errorreaseon=' did you requested the front camera although there is none?';
      } else if (error.name === 'StreamApiNotSupportedError') {
         errorreaseon=' browser seems to be lacking features';
      }
      this.$f7.dialog.alert(errorreaseon, error.name,()=>{
         this.$f7.views.main.router.back();
      });
    });
  }
  }
}
</script>

<style>
.spkrimgx{
  width:45px;
  border-radius: 25%;
  background: #a1ceff;
}
.textargris textarea{
  background: #f4f4f4;
    padding: 4px 4px;
}
</style>