
import HomePage from '../pages/home.vue';
import students from '../pages/students.vue';
import daily_students from '../pages/daily_students.vue';
import daily_staff from '../pages/daily_staff.vue';
import daily_visitors from '../pages/daily_visitors.vue';
import rightpanel from '../pages/rightpanel.vue';
import message from '../pages/message.vue';



import staff from '../pages/staff.vue';
import staff_two from '../pages/staff_two.vue';
import absence_staff from '../pages/absence_staff.vue';

import scannerpre from '../pages/scannerpre.vue';

import NotFoundPage from '../pages/404.vue';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/rightpanel/',
    component: rightpanel,
  },
  {
    path: '/message/',
    component: message,
  },
  {
    path: '/scannerpre/',
    component: scannerpre,
  },
  {
    path: '/staff_two/',
    component: staff_two,
  },
  {
    path: '/absence_staff/',
    component: absence_staff,
  },
  {
    path: '/students/',
    component: students,
  },
  {
    path: '/daily_students/',
    component: daily_students,
  },
  {
    path: '/daily_staff/',
    component: daily_staff,
  },
  {
    path: '/daily_visitors/',
    component: daily_visitors,
  },
  {
    path: '/staff/',
    component: staff,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
