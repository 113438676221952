<template>
  <f7-page class="backgreen" name="qrmaker">
         <f7-navbar :title="numerofcleared+' cleared to attend'" subtitle="Status">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
    <f7-list class="no-margin">
      <f7-list-item header="Completed on" :title="passportinfo.wh">
        <f7-icon slot="after" class="text-color-primary" material="verified_user"></f7-icon>
      </f7-list-item>
    </f7-list>
    <f7-block class="text-align-center text-color-primary margin-half " >
      <f7-chip
      v-for="stu in passportinfo.pld"
      :key="stu.xisd" :text="stu.gnam+(stu.xtype=='tfid' || stu.xtype=='vsid' ? '' : ' grade')"
      media-bg-color="primary"
      >
      <f7-icon slot="media" material="person"></f7-icon>
      </f7-chip>
      </f7-block>
      <qrcode-vue :value="stringToQode" size="300" level="L" class="margin-top text-align-center"></qrcode-vue>
      <f7-block class="text-align-center no-margin-top easyread">{{easytoread}}</f7-block>
<f7-block>
  <p v-if="!userinfo.email" class="text-align-center">Display, send or save a screenshot of this image before closing the app</p>
  </f7-block>
  </f7-page>
</template>
<script>
  import QrcodeVue from 'qrcode.vue';
import { mapState } from 'vuex';

export default {
   components: {
      QrcodeVue,
    },
    name: 'qrmaker',
     props:['passportinfo'],
  data(){
    return{

    }
  },
  computed:{
      ...mapState(['userinfo','subdomain']),
      numerofcleared(){
        return Object.keys(this.passportinfo.pld).length
      },
      easytoread(){
        let passky = this.passportinfo.passid;
        let primx = passky.substring(0, 2);
        let num = passky.substring(2, 6);
        let lastx = passky.substring(6, 8);
        return primx+' - '+num+' - '+lastx
      },
      leuid(){
        return this.$firebase.auth().currentUser.uid || 0;
      },
     stringToQode(){
     return 'https://'+this.subdomain+'.besafe.school?r='+this.passportinfo.passid+'&d='+this.passportinfo.date+'&q=1';
   },
  },
  mounted(){
    console.log(this.passportinfo);
  },
  methods:{

  }
}
</script>
<style>

.cleared{
  font-size:14px;
  font-weight: bolder;
}
.backgreen{
  background: #deffee;
}
.backyealloy{
  background: #fff6af;
}
 .easyread{
  font-size: 40px;
  font-weight: bold;
 }
</style>
