<template>
  <f7-page name="form">
    <f7-navbar title="Faculty/Staff Absence Report Form" subtitle="All information is required" >
    </f7-navbar>

  <f7-list  no-hairlines-md>
      <f7-list-input
    label="First Name"
    type="text"
    placeholder="First Name"
      :value="name"
    @input="name = $event.target.value"
    clear-button
  >
    <f7-icon material="account_circle" slot="media"></f7-icon>
  </f7-list-input>
  <f7-list-input
    label="Last Name"
    type="text"
    placeholder="Last Name"
     :value="lastname"
    @input="lastname = $event.target.value"
    clear-button
  >
    <f7-icon material="account_circle" slot="media"></f7-icon>
  </f7-list-input>

    <f7-list-input
    label="email"
    type="email"
    placeholder="email"
      :value="email"
    @input="email = $event.target.value"
    clear-button
  >
    <f7-icon material="email" slot="media"></f7-icon>
  </f7-list-input>

<f7-list-input
    label="Phone number"
    type="number"
    placeholder="Phone number"
      :value="phone"
    @input="phone = $event.target.value"
    clear-button
  >
    <f7-icon material="call" slot="media"></f7-icon>
  </f7-list-input>

  </f7-list>
    <f7-block strong>
      <f7-row tag="p">
        <f7-button class="col" @click="logmeOut()" large raised>Cancel</f7-button>
        <f7-button class="col" @click="sendEmail()" :disabled="!canSubmit" large fill raised>Submit</f7-button>
      </f7-row>
    </f7-block>
  </f7-page>
</template>


<script>
import { mapState } from 'vuex';

export default {
  data(){
    return {
      lastname: null,
      name: null,
      email:null,
      phone:null
    }
  },
     computed:{
      ...mapState(['mysymptoms']),
      canSubmit(){
        return this.lastname && this.name && this.email && this.phone;
      }
	},
  methods:{
      sendEmail() {
        let savedData={
          lastname: this.lastname,
          name: this.name,
          email: this.email,
          phone: this.phone,
          clp: this.$store.state.subdomain
        };
        console.log(savedData);
          this.$f7.dialog.preloader('Sending report...');
           let reportstaff = this.$firebase.functions().httpsCallable('reportstaff');
            reportstaff(savedData).then((ladata)=> {
              console.log('llego data',ladata);
              this.$f7.dialog.close();
                this.$f7.dialog.alert('Thank you. You report has been sent. The form will close now.','Report Sent Succesfully',()=>{
                  this.logmeOut();
                });
            }).catch((error)=>{
              console.log('error en fx',error);
              this.$f7.dialog.close();
              this.$f7.dialog.alert(error.message,'Error');
            });
      },
  logmeOut(){
     this.lastname=null;
      this.name=null;
      this.email=null;
      this.phone=null;
       console.log('Logging out');
        this.$f7.dialog.preloader('Clearing cache...');
        this.$firebase.auth().signOut().then(()=> {
        this.$store.commit('logmeout');
          console.log('Sign-out successful.');
          this.$f7.dialog.close();
        }).catch((error)=> {
          this.$f7.dialog.close();
          console.log('Sign-out error.', error);
          this.$f7.dialog.alert(error.message,error);
        });
    }
  }
}
</script>