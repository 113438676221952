<template>
  <f7-page
      infinite
      :infinite-distance="50"
      :infinite-preloader="showPreloader"
      @infinite="llamandoInfinito"
      ptr 
      @ptr:refresh="llamandoPullreferh"
      >
       <f7-navbar >
           <f7-nav-title>Messages</f7-nav-title>
          <f7-nav-right>
         <f7-link @click="startretreivalagain()" icon-material="refresh"></f7-link>
          </f7-nav-right>
       </f7-navbar>
        <f7-list class="notilist no-margin-top">
          <f7-list-item v-for="noti in contentToList" :key="noti.key" 
          :header="noti.type=='abrep_staff'?'Staff':noti.grnam"
          :title="noti.stunm"
          :subtitle="noti.type=='abrep_staff'?'Absence Report from Staff':'Absence Report from Student'"
          :footer="noti.wh?$moment.unix(noti.wh).format('LLL'):null"
          :class="{read: noti.read, unread: !noti.read}"
          link="#"
          @click="gotomessate(noti)"
          swipeout
          @swipeout:delete="borraNotix(noti.key)"
          >
          <f7-icon :material="noti.read?'drafts':'markunread'" slot="media"></f7-icon>
          <f7-swipeout-actions right>
            <f7-swipeout-button delete>Delete</f7-swipeout-button>
          </f7-swipeout-actions>
          </f7-list-item>
          <f7-list-item v-if="!contentToList.length" title="You don't have notifications"></f7-list-item>
        </f7-list>
      </f7-page>
</template>
<script>
export default {
    data() {
        return {
            contentToList:[],
            iAmLoading: false,
            showPreloader: true,
            lastnotikeyCargada:null,
            laultima:null,
            contentYaRendereado:{},
            postsbyload: 22,
            observat: null,
            noleidos:{}
        };
      },
    computed:{
        sourceToQuery(){
            let source=null;
            if(this.$store.state.userinfo.email){
                let cleanemail = this.$store.state.userinfo.email.split('.').join('|');
                source = 'notix/' + cleanemail
            }
            return source
        },
        stascoord(){
          return this.$store.getters.stascoord || {}
        },
        triggernotix(){
          return this.$store.state.triggernotix
        }
    },
    watch:{
      sourceToQuery(newval){
        console.log('hay usuario, pedir unread');
        this.observableCounter();
      },
      triggernotix(newval){
        console.log('abirendo panel, pedir');
       this.llamandoInfinito();
        
      }
    },
    created(){
      
    },
    methods:{
      borraNotix(key){
        this.$firebase.database().ref(this.sourceToQuery+'/'+key).set(null);
      },
      observableCounter(){
         this.observat = this.$firebase.database().ref(this.sourceToQuery).orderByChild('read').equalTo(null);
        this.observat.on('child_added', (data)=> {
          this.noleidos[data.key]=true;
          let noleidoscount=Object.keys(this.noleidos).length;
          this.$store.commit('setWhatTo',{what: 'unreadnots', to: noleidoscount })
          // if(!this.contentYaRendereado[data.key]){
          //   this.llamarConRangos({checkNuevas:true});
          // }
        });

        this.observat.on('child_removed', (data)=> {
          if(this.noleidos[data.key]){
            delete this.noleidos[data.key];
            let noleidoscount=Object.keys(this.noleidos).length;
            this.$store.commit('setWhatTo',{what: 'unreadnots', to: noleidoscount })
          }
        });
      },
      gotomessate(message){
        console.log('navigatin');
       this.$firebase.database().ref(this.sourceToQuery+'/'+message.key+'/read').set(true)
        this.$store.commit('setObjTo',{what:'focusedMessage',to: message});
        let index = this.contentToList.findIndex((item) =>item.key === message.key);
        if(index!=-1){
          let aguardar=Object.assign({},this.contentToList[index],{read:true})
         this.contentToList.splice(index, 1, aguardar);
        }

        this.$f7router.navigate('/message/')
      },
        llamandoPullreferh(done){
            if(this.sourceToQuery){
                console.log('ptr iniciando');
                if(this.laultima){
                    //ir a buscar a ver si hay mas
                    this.llamarConRangos({checkNuevas:true,done:done});
                }
                else{
                    //no hay , hacer esfuerzo por traer
                    this.startretreivalagain({done:done});
                }
            }
            else{
                this.showPreloader = false;
            }
        },
        llamandoInfinito(){
            console.log('llegando al infinito');
           // Exit, if iAmLoading in progress
           if (this.iAmLoading) return;
           console.log('no estaba cargando, puedo seguir');
           // Set iAmLoading flag
           this.iAmLoading = true;
           this.llamarConRangos({});
       },
       startretreivalagain(payload={}){
        console.log('Iniciando primer retreival');
        this.iAmLoading = true;
        this.lastnotikeyCargada=null;
        this.laultima=null;
        this.contentYaRendereado={};
        this.contentToList=[];
       this.llamarConRangos(payload);
    },
    llamarConRangos(payload){
        let promload;
        if(payload.checkNuevas){
            console.log('pidiendo nuevas');
            promload=this.$firebase.database().ref(this.sourceToQuery).orderByKey().startAt(this.laultima);
        }
        else{
            if(!this.lastnotikeyCargada){
                //es primer llamado, solo pedir las ultimas #postbyload
                promload=this.$firebase.database().ref(this.sourceToQuery).orderByKey().limitToLast(this.postsbyload);
            }
            else{
                promload=this.$firebase.database().ref(this.sourceToQuery).orderByKey().endAt(this.lastnotikeyCargada).limitToLast(this.postsbyload);
            }
        }
        console.log('llamar con rangos a '+promload);
        return promload.once('value').then((snapshot)=>{
            //llego data Ok. Voy a borrar el holder de lastnotikeycargada, para poder anotar el nuevo
            this.lastnotikeyCargada=null;
            let ultimaDelListado=null;
            //arreglo interno, para luego mezclar con arreglo global
            let innerArray=[];
            if(snapshot.exists()){

                snapshot.forEach((data) => {
                        //la primera que me llega en esta pasada, sera la ultima key cargada. Luego omitir
                        if(!this.lastnotikeyCargada){
                        this.lastnotikeyCargada=data.key;
                    }
                    //preparar objeto con la llave incluida
                    let elemntoToLoad=data.val();
                    elemntoToLoad.key=data.key;
                    ultimaDelListado=elemntoToLoad;//sobre escribir la ultima

                    //ver si aun tengo privielgios, si no, borrarmelo
                    if(elemntoToLoad.type=='abrep' && !this.stascoord[elemntoToLoad.gid]){
                      //stascoord
                      console.log('deleting SIN PRIVS EN',elemntoToLoad,elemntoToLoad.key)
                      this.$firebase.database().ref(this.sourceToQuery+'/'+elemntoToLoad.key).set(null);
                    }
                    else{
                        //ver si la agrego o no al arreglo
                        if(!this.contentYaRendereado[data.key]){
                            //no le habia agregado, agregar
                            innerArray.unshift(elemntoToLoad);
                            this.contentYaRendereado[data.key]=true;
                        }
                      }
                    });
            }
            //Si al terminar el loop, no tengo defindia ULTIMA, es por que es la primera llamada. Guardar la ultima
            if(!this.laultima && ultimaDelListado){
                this.laultima=ultimaDelListado.id;
            }
            //al terminar el loop, mezclar el arreglo interno, con el arreglo padre
            if(payload.checkNuevas){
                this.contentToList=innerArray.concat(this.contentToList);
                this.iAmLoading = false;
            }
            else{
                this.contentToList=this.contentToList.concat(innerArray);
                 if(snapshot.numChildren()<this.postsbyload){
                    this.showPreloader = false;
                }
                else{
                    this.iAmLoading = false;
                }
            }
            //si llegaron menos de las que pedi, se acabaron, ensconder el preloader de infinite scroll y no apagar el freno de iAmLoading
            console.log('done loading',this.contentToList);
             if(payload.done){
               payload.done();
             }
        });
    }
    }
}
</script>
<style>
.read{
  background: #f5f5f5;
}
.unread{
  background: #FFF;
}
.unread .item-title{
  font-weight: bold!important;
}
.notilist.list .item-media + .item-inner{
  margin-left: 0px!important
}
</style> 