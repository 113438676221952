<template>
  <f7-page name="form">
    <f7-navbar title="Staff/Faculty Screening" subtitle="Section 1 of 2" >
    </f7-navbar>

    <f7-block>Today or since the last time you completed this screening, have you experienced any of the following?</f7-block>
     <f7-block-title>Select all that apply</f7-block-title>
    <f7-list class="nowhitspae">
      <f7-list-item
        checkbox
        v-for="chbox in sta"
        :key="chbox.i"
        name="my-symptoms"
        :value="chbox.i"
        :title="chbox.n"
         :checked="mysymptoms.includes(chbox.i)"
          @change="checksymptoms"
      ></f7-list-item>
      <f7-list-item divider title="or"></f7-list-item>
       <f7-list-item
        checkbox
        name="my-symptoms-none"
        title="None of the above"
         :checked="noneofabove"
          @change="updateNoneofabove"
      ></f7-list-item>
    </f7-list>
    <f7-block strong>
      <f7-row tag="p">
        <f7-button class="col" @click="logmeOut()" large raised>Cancel</f7-button>
        <f7-button class="col" href="/staff_two/" :disabled="!noneofabove && mysymptoms.length==0" large fill raised>Next</f7-button>
      </f7-row>
    </f7-block>
  </f7-page>
</template>


<script>
import { mapState, mapGetters } from 'vuex';

export default {
  data(){
    return {
      noneofabove: false
    }
  },
     computed:{
      ...mapState(['mysymptoms']),
      ...mapGetters(['sta'])
	},
  methods:{
     checksymptoms(event) {
        let symptompid = event.target.value;
        if (event.target.checked) {
             this.noneofabove=false;
          this.$store.commit('pushToArray',{array:'mysymptoms',value:symptompid});
        } else {
         this.$store.commit('removeFromArray',{array:'mysymptoms',value:symptompid});
        }
      },
      updateNoneofabove(event) {
        if (event.target.checked) {
          this.noneofabove=true;
         this.$store.commit('setWhatTo',{what:'mysymptoms',to: []});
        } else {
          this.noneofabove=false;
        }
      },
    logmeOut(){
       console.log('Logging out');
     
        this.$f7.dialog.preloader('Clearing cache...');
        this.$firebase.auth().signOut().then(()=> {
          console.log('Sign-out successful.');
            this.$store.commit('logmeout');
          this.$f7.dialog.close();
        }).catch((error)=> {
          this.$f7.dialog.close();
          console.log('Sign-out error.', error);
          this.$f7.dialog.alert(error.message,error);
        });
    }
  }
}
</script>
<style>
.nowhitspae .item-title{
  white-space: unset!important;
}
</style>