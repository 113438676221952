<template>
    <f7-page class="grisback" name="daily_students">
        <f7-navbar
            :title="$moment().format('dddd, LL')"
            subtitle="Complete information for all your children"
            back-link="Back"
        >
        </f7-navbar>

        <f7-block-title class="margin-top"
            >My Child{{ cuantosChildren > 1 ? "dren" : "" }}</f7-block-title
        >
        <f7-card
            v-for="prokid in recsymptoms"
            :key="'child_' + prokid.sudoid"
            class="childcard"
            :padding="false"
        >
            <f7-card-header>
                <f7-list media-list>
                    <f7-list-item
                        :header="`Grade/Level: ${prokid.info.gradmet}`"
                        :title="prokid.nam"
                        ><img src="../static/icons/avatar.png" slot="media"
                    /></f7-list-item>
                </f7-list>
            </f7-card-header>
            <f7-card-content>
                <f7-list accordion-list>
                    <f7-list-item
                        :class="'sym_' + prokid.sudoid"
                        accordion-item
                        title="Symptoms"
                    >
                        <span slot="after">
                            <f7-icon
                                v-if="prokid.symptoms.length"
                                class="text-color-red"
                                material="warning"
                            ></f7-icon>
                            <f7-icon
                                v-else-if="prokid.noneofabove"
                                class="text-color-green"
                                material="check_circle_outline"
                            ></f7-icon
                            ><span v-else>Please answer</span>
                        </span>
                        <f7-accordion-content class="padforce">
                            <f7-block
                                >Today or since the last time you completed this
                                screening, has {{ prokid.nam }} experienced any
                                of the following?</f7-block
                            >
                            <f7-block-title
                                class="margin-top-half text-align-center"
                                >Select all that apply</f7-block-title
                            >
                            <f7-list
                                inset
                                class="nowhitspae listinsetr bg-color-white fixcheckx"
                                :class="{ disabled: prokid.report }"
                            >
                                <f7-list-item
                                    checkbox
                                    v-for="chbox in stu"
                                    :key="chbox.i"
                                    name="my-symptoms"
                                    :value="chbox.i"
                                    :title="chbox.n"
                                    :checked="prokid.symptoms.includes(chbox.i)"
                                    @change="
                                        checksymptoms($event, prokid.sudoid)
                                    "
                                ></f7-list-item>
                                <f7-list-item divider title="or"></f7-list-item>
                                <f7-list-item
                                    checkbox
                                    name="my-symptoms-none"
                                    title="None of the above"
                                    :checked="prokid.noneofabove"
                                    @change="
                                        updateNoneofabove($event, prokid.sudoid)
                                    "
                                ></f7-list-item>
                            </f7-list>
                        </f7-accordion-content>
                    </f7-list-item>
                    <f7-list-item
                        :class="'con_' + prokid.sudoid"
                        accordion-item
                        title="Contacts"
                    >
                        <span slot="after">
                            <f7-icon
                                v-if="prokid.exposure === 'yes'"
                                class="text-color-red"
                                material="warning"
                            ></f7-icon>
                            <f7-icon
                                v-else-if="prokid.exposure === 'no'"
                                class="text-color-green"
                                material="check_circle_outline"
                            ></f7-icon
                            ><span v-else>Please answer</span>
                        </span>

                        <f7-accordion-content class="padforce">
                            <f7-block>
                                <div class="pre">
                                    {{
                                        contacts_question.length
                                            ? contacts_question
                                            : "Have you been exposed to a household or close family member who has tested positive for or is suspected of having COVID-19 in the past 14 days?"
                                    }}
                                </div>
                            </f7-block>
                            <f7-list
                                inset
                                class="listinsetr bg-color-white margin-top"
                                :class="{ disabled: prokid.report }"
                            >
                                <f7-list-item
                                    radio
                                    name="radio"
                                    value="yes"
                                    title="Yes"
                                    :checked="prokid.exposure === 'yes'"
                                    @change="chgexposure(prokid.sudoid, 'yes')"
                                ></f7-list-item>
                                <f7-list-item
                                    radio
                                    name="radio"
                                    value="no"
                                    title="No"
                                    :checked="prokid.exposure === 'no'"
                                    @change="chgexposure(prokid.sudoid, 'no')"
                                ></f7-list-item>
                            </f7-list>
                        </f7-accordion-content>
                    </f7-list-item>
                </f7-list>
            </f7-card-content>
            <f7-card-footer>
                <span></span>
                <span
                    v-if="
                        !prokid.exposure ||
                            (!prokid.symptoms.length && !prokid.noneofabove)
                    "
                    >Answer both questions</span
                >
                <f7-button
                    v-else-if="
                        (prokid.exposure === 'yes' || prokid.symptoms.length) &&
                            !prokid.report
                    "
                    outline
                    @click="absenceform(prokid.sudoid)"
                    >Send absence report</f7-button
                >
                <span
                    v-else-if="
                        (prokid.exposure === 'yes' || prokid.symptoms.length) &&
                            prokid.report
                    "
                    >Absence report sent succesfully</span
                >
                <span v-else>Cleared. Get pass</span>
            </f7-card-footer>
        </f7-card>

        <f7-block v-if="hayAlgopendiente"
            >Complete both questions for
            {{
                cuantosChildren > 1
                    ? "all your children"
                    : Object.values(this.recsymptoms)[0].nam.split(" ")[0]
            }}</f7-block
        >
        <f7-block v-else-if="childconpases.length" strong>
            <f7-button
                v-if="childconpases.length == 1"
                class="col"
                @click="getpassess(true)"
                href="#"
                large
                fill
                raised
                >Get Pass for
                {{
                    Object.values(childconpases)[0].nam.split(" ")[0]
                }}</f7-button
            >
            <template v-else>
                <f7-button
                    class="col"
                    @click="getpassess(true)"
                    href="#"
                    large
                    fill
                    raised
                    >Get single pass for all</f7-button
                >
                <f7-block class="text-align-center margin">- or -</f7-block>
                <f7-button
                    class="col"
                    @click="getpassess(false)"
                    href="#"
                    large
                    fill
                    raised
                    >Get Passes for each children</f7-button
                >
            </template>
            <p class="text-align-center">
                You can text or email a link to the pass{{
                    childconpases.length > 1 ? "es" : ""
                }}
                to the child{{ childconpases.length > 1 ? "dren" : "" }}
            </p>
        </f7-block>
        <f7-block v-else-if="!hayAlgopendiente">You are all set.</f7-block>

        <f7-popup
            class="demo-popup"
            :opened="popupOpened"
            @popup:closed="popupOpened = false"
        >
            <absence
                v-if="selectedForRepor"
                :child="recsymptoms[selectedForRepor]"
                @formok="firmaOk()"
                @cerrarmodal="popupOpened = false"
            ></absence>
        </f7-popup>
    </f7-page>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import absence from "../pages/absence";

export default {
    components: {
        absence,
    },
    data() {
        return {
            recsymptoms: {},
            //validar abajo
            selectedForRepor: null,
            popupOpened: false,
        };
    },
    computed: {
        ...mapState(["settings", "mysymptoms"]),
        ...mapGetters([
            "stu",
            "myvldchildrn",
            "self_def_stus",
            "contacts_question",
        ]),
        childconpases() {
            let hayKidsOk = Object.values(this.recsymptoms).filter(
                (unchild) => {
                    return (
                        unchild.exposure == "no" &&
                        !unchild.symptoms.length && unchild.noneofabove
                    );
                }
            );
            return hayKidsOk;
        },
        hayFormsPendientes() {
            let pendingofmrs = Object.values(this.recsymptoms).some(
                (unchild) => {
                    return (
                        !unchild.report &&
                        (unchild.exposure == "yes" || unchild.symptoms.length)
                    );
                }
            );
            return pendingofmrs;
        },
        hayAlgopendiente() {
            let pendientes = Object.values(this.recsymptoms).some((unchild) => {
                return (
                    !unchild.exposure ||
                    (!unchild.symptoms.length && !unchild.noneofabove)
                );
            });
            return pendientes;
        },
        cuantosChildren() {
            return (
                Object.keys(this.myvldchildrn).length +
                Object.keys(this.self_def_stus).length
            );
        },
    },
    created() {
        Object.keys(this.self_def_stus).forEach((onetsid) => {
            let neo = {};
            neo[onetsid] = {
                symptoms: [],
                noneofabove: false,
                exposure: null,
                report: null,
                nam: this.self_def_stus[onetsid].xnm,
                info: this.self_def_stus[onetsid],
                sudoid: onetsid,
            };
            this.recsymptoms = Object.assign({}, this.recsymptoms, neo);
        });
        Object.keys(this.myvldchildrn).forEach((onetsid) => {
            let neo = {};
            neo[onetsid] = {
                symptoms: [],
                noneofabove: false,
                exposure: null,
                report: null,
                nam:
                    this.myvldchildrn[onetsid].fnm +
                    " " +
                    this.myvldchildrn[onetsid].lnm,
                info: this.myvldchildrn[onetsid],
                sudoid: onetsid,
            };
            this.recsymptoms = Object.assign({}, this.recsymptoms, neo);
        });
        console.log(this.recsymptoms);
    },
    methods: {
        firmaOk() {
            this.recsymptoms[this.selectedForRepor].report = true;
        },
        getpassess(justone = false) {
            console.log("gettingpas");
            if (this.hayAlgopendiente) {
                let frase =
                    Object.values(this.recsymptoms).length > 1
                        ? " for every child"
                        : "";
                return this.$f7.dialog.alert(
                    "Please answer both questions regarding symptoms and contacts" +
                        frase,
                    "Incomplete answers"
                );
            }

            let faltaunreport = Object.values(this.recsymptoms).some(
                (child) => {
                    return (
                        (child.exposure === "yes" || child.symptoms.length) &&
                        !child.report
                    );
                }
            );
            if (faltaunreport) {
                let frase =
                    Object.values(this.recsymptoms).length > 1
                        ? "One of your children"
                        : "Your child";
                return this.$f7.dialog.alert(
                    "Use the button below the box to send the absence report form.",
                    frase + " was not cleared to attend school."
                );
            }
            //all good, get passess
            let chiltosend = this.childconpases.map((unchil) => {
                return unchil.info;
            });
            let tsnedat = {
                clp: this.$store.state.subdomain,
                childs: chiltosend,
                justone: justone,
            };
            console.log(tsnedat);
            this.$f7.dialog.preloader("Generating QR Code for display...");
            let dailychecks = this.$firebase
                .functions()
                .httpsCallable("dailychecks");
            dailychecks(tsnedat)
                .then((ladata) => {
                    console.log("llego data", ladata);
                    let payload = ladata.data;
                    if (payload.error) {
                        throw new Error(payload.error);
                    }
                    this.$f7.views.main.router.back();
                    this.$f7.dialog.close();
                })
                .catch((error) => {
                    console.log("error en fx", error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, "Error");
                });
        },
        absenceform(xid) {
            this.selectedForRepor = xid;
            this.popupOpened = true;
        },
        checksymptoms(event, xid) {
            let symptompid = event.target.value;
            if (event.target.checked) {
                this.recsymptoms[xid].noneofabove = false;
                if (!this.recsymptoms[xid].symptoms.includes(symptompid)) {
                    this.recsymptoms[xid].symptoms.push(symptompid);
                }
            } else {
                let indiexist = this.recsymptoms[xid].symptoms.findIndex(
                    (unitem) => unitem == symptompid
                );
                if (indiexist != -1) {
                    this.recsymptoms[xid].symptoms.splice(indiexist, 1);
                }
            }
        },
        chgexposure(xid, value) {
            this.recsymptoms[xid].exposure = value;
            this.$f7.accordion.close(".con_" + xid);
            this.checkiffailed(xid);
        },
        updateNoneofabove(event, xid) {
            if (event.target.checked) {
                this.recsymptoms[xid].noneofabove = true;
                this.recsymptoms[xid].symptoms = [];
                this.$f7.accordion.close(".sym_" + xid);
            } else {
                this.recsymptoms[xid].noneofabove = false;
            }
        },
        checkiffailed(xid) {
            let kidinfo = this.recsymptoms[xid] || {};
            if (kidinfo.symptoms.length || kidinfo.exposure == "yes") {
                return this.$f7.dialog.alert(
                    "Your child is not cleared to attend school. Please send the following absence form.",
                    "Not cleared",
                    () => {
                        this.absenceform(xid);
                    }
                );
            }
        },
    },
};
</script>
<style>
.listinsetr {
    margin-left: calc(
        var(--f7-list-inset-side-margin) + var(--f7-safe-area-outer-left)
    ) !important;
    margin-right: calc(
        var(--f7-list-inset-side-margin) + var(--f7-safe-area-outer-right)
    ) !important;
}
.nowhitspae .item-title {
    white-space: unset !important;
}
.grisback .page-content {
    background: #e6e6e6;
}
.childcard img {
    width: 30px;
    margin: 0 auto;
}
.childcard .card-header {
    padding: 0px !important;
    display: block;
}

.accordion-item-opened .padforce {
    padding-top: 10px !important;
    padding-bottom: 20px !important;
    background: #f0f3ff;
}
.fixcongrade.icon {
    color: #545861;
    font-size: 30px;
    top: -10px;
    margin: 0 auto;
}

.fixcheckx .icon.icon-checkbox {
    margin-right: 20px !important;
}
</style>
