import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        appName:'besafe',
        appUrl: 'https://besafe.school',
        appVersion: '2.3.3 ',
        activeTab:'/',
        loginAbierto: true,
        mysymptoms:[],
        exposure: null,
        thermometer:null,
        temperature: null,
        genKey: null,
        warning: null,
        userinfo:{},
        subdomain: 'demo',
        childrn: [],
        settings: {},
        stu:{},
        sta:{},
        alreadyOk:{},
        notifications:[],
        unreadnots: 0,
        triggernotix: 0,
        focusedMessage:{}
    },
    getters: {
        kore: state =>{
            return state.settings.kore
        },
        onegrade: state => {
            return (gid) => {
                let grados =  state.settings.grades || [];
                return grados.find(ungr=> ungr.gid==gid);
            }
          },
        myvldchildrn: (state) => {
          let myactos = state.settings.myaccounts || {};
          return myactos.stus || {};
        },
        stascoord: (state) => {
            let myactos = state.settings.myaccounts || {};
            return myactos.stas || {};
          },
        self_def_stus: (state) => {
            let myselfs = state.settings.self_accounts || {};
            return myselfs.stus || {};
        },
        stu: (state) => {
          return state.settings.stu || [];
        },
        sta: (state) => {
            return state.settings.sta || [];
        },
        contacts_question: (state) => {
            if (typeof state.settings.contactsQuestion === "string") {
                return state.settings.contactsQuestion.replace(/\\n/g, "\n");
            } else {
                return "";
            }
        }
    },
    mutations: {
        updateImVisitorName: (state, payload) => {
            const { fnm, lnm } = payload;
            state.settings.imvisitor.fnm = fnm;
            state.settings.imvisitor.lnm = lnm;
        },
        setImVisitor: (state, newVisitor) => {
            state.settings.imvisitor = newVisitor;
        },
        addOkToday: (state, payeload)=>{
            //alreadyOk
            if(state.alreadyOk[payeload.hoy]){
                //update de aqui para arriba
                let nuevadata=Object.assign({},payeload.stu);
                state.alreadyOk[payeload.hoy] = Object.assign({},state.alreadyOk[payeload.hoy],nuevadata)
            }
            else{
                //update desde raiz
                let diadata={};
                diadata[payeload.hoy]=Object.assign({},payeload.stu);
                state.alreadyOk = Object.assign({},state.alreadyOk,diadata);
            }
        },
        removeSelfStu: (state,tsid)=>{
            let myselfstus = Object.assign({},(state.settings.self_accounts.stus || {}));
            delete myselfstus[tsid];
            state.settings.self_accounts.stus= Object.assign({},myselfstus);
        },
        removeCoreStu: (state,sid)=>{
            let myselfstus = Object.assign({},(state.settings.myaccounts.stus || {}));
            delete myselfstus[sid];
            state.settings.myaccounts.stus= Object.assign({},myselfstus);
        },
        addSelfStu: (state,payload)=>{
            let myactos = state.settings.self_accounts || {};
            let neo={};
            neo[payload.tsid]=payload;
            if(!myactos.stus){
                state.settings.self_accounts=Object.assign({},state.settings.self_accounts,{stus:neo});
            }
            else{
                state.settings.self_accounts.stus=Object.assign({},state.settings.self_accounts.stus,neo);
            }
        },
        addStuAcctoun: (state,payload)=>{
            let myactos = state.settings.myaccounts || {};
            let neo={};
            neo[payload.sid]=payload;
            if(!myactos.stus){
                state.settings.myaccounts=Object.assign({},state.settings.myaccounts,{stus:neo});
            }
            else{
                state.settings.myaccounts.stus=Object.assign({},state.settings.myaccounts.stus,neo);
            }
        },
        setWhatTo: (state,payload)=>{
            state[payload.what] = payload.to;
        },
        pushToArray: (state,payload) => {
          state[payload.array].push(payload.value);
      },
      removeFromArray: (state,payload)=>{
        let indiexist = state[payload.array].findIndex(unitem => unitem[payload.attr]==payload.value);
        if(indiexist!=-1){
            state[payload.array].splice(indiexist,1);
        }
    },
    logmeout:(state)=>{
          state.mysymptoms=[];
          state.exposure=null;
          state.genKey=null;
          state.thermometer=null;
          state.temperature=null;
          state.warning=null;
      },


        mergeObjTo:(state,payload)=>{
            state[payload.what]=Object.assign({},state[payload.what],payload.to);
        },
        setObjTo:(state,payload)=>{
            state[payload.what]=Object.assign({},payload.to);
        },
        removeFromArray(state, payload){
            let index = state[payload.array].findIndex((item) =>item === payload.value);
            if(index !== -1){
                state[payload.array].splice(index, 1);
            }
        },
    },
    actions:{

    }
})