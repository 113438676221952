<template>
  <f7-page class="grisback" name="scannerpre">
    <f7-navbar :subtitle="$moment().format('dddd, LL')" title="Passes official scanner"  back-link="Back">
    </f7-navbar>


<f7-block-title class="margin-top">Visual scanner</f7-block-title>

<f7-block>
  <f7-button @click="scannerOpened = true" fill raised>Scan using the camera</f7-button>
  <p>Works ok on android devices. Some iOS devices have issues. If using iOS, preffer scanning by typing the QR code below.</p>
</f7-block>

<f7-block>
 <f7-row>
    <f7-col width="100" medium="80">
      <f7-list class="no-margin" no-hairlines-md>
        <f7-list-input
          label="Code"
          info="dashes are irelevant, just for ease of read"
          type="text"
           :value="checkcode"
            @input="typemorecode($event.target.value)"
          placeholder="XX-0000-XX"
        >
        </f7-list-input>
      </f7-list>
    </f7-col>
    <f7-col width="100" medium="20"><f7-button @click="validate()" outline raised>Validate</f7-button></f7-col>
  </f7-row>
</f7-block>
<f7-block-title>Succesful scans will appear below</f7-block-title>
<f7-list>
  <f7-list-item v-for="(unwn,idx) in latests" :key="'wn'+idx" :title="unwn.nam" :after="unwn.grade" :footer="unwn.passid"></f7-list-item>
</f7-list>


 <f7-popup class="demo-popup" :opened="scannerOpened" @popup:closed="scannerOpened = false">
    <scanner @newscan="pushSuccesfulscann" v-if="scannerOpened"></scanner>
    </f7-popup>
  </f7-page>
</template>


<script>
import { mapState, mapGetters } from 'vuex';
import scanner from '../pages/scanner.vue'
export default {
 /*
      if(!this.$f7.device.ios && !this.$f7.device.macos){
          this.canIscann=true;
        }
 */
components:{
  scanner
},
  data(){
    return {
      checkcode: '',
      latests:[],
      scannerOpened:false
     // observablehere: null
    }
  },
     computed:{
      ...mapState(['settings']),
      ...mapGetters(['kore'])
   
  },
  created(){
      // this.observablehere = this.$firebase.database().ref('repox/'+this.kore+'/'+this.$moment().format('YYYYMMDD')).orderByChild('skn').equalTo(true);
      // this.observablehere.on('child_added', (data)=> {
      //   console.log('viene passeecaneado',data.key,data.val())
      //   this.latests.push();
      // });

      // this.observablehere.on('child_changed',  (data)=> {
        
      // });

      // this.observablehere.on('child_removed', (data)=> {
          
      // });
  },
  methods:{
    typemorecode(newvalue){
      let largo=newvalue.length;
      let lastchar = newvalue.substr(largo - 1);
      let agraar=newvalue;
      console.log(lastchar)
      if(largo==2 || largo==7){
        agraar=newvalue+'-';
      }
      this.checkcode = agraar;
    },
    pushSuccesfulscann(linfo){
          linfo.wh = this.$moment.unix(linfo.wh).format('LLL')
          linfo.date= this.$moment().format('YYYYMMDD')
            console.log('scanmeta',linfo);
           let ppl = linfo.pld || {};
           let pplids = Object.values(ppl) || [];
           let whoms='';
           pplids.forEach(unwn => {
             let elnam = unwn.meta.xnm || (unwn.meta.fnm+' '+unwn.meta.lnm);
             let toshow={
               passid: linfo.passid,
               wh: linfo.wh,
               grade: unwn.gnam,
               nam: elnam
             };
             whoms+=elnam+' ('+ unwn.gnam +'), ';
             this.latests.unshift(toshow);
           });
           console.log('latests',this.latests);
            return this.$f7.dialog.alert('Valid for '+whoms+' reported on '+linfo.wh,'Valid Pass');
    },
    validate(){
       this.$f7.dialog.preloader('Validating pass...');
        let getpass = this.$firebase.functions().httpsCallable('getpassnew');
        return getpass({domain: this.$store.state.subdomain, pid: this.checkcode, ymd: this.$moment().format('YYYYMMDD'), validate: true}).then((ladata)=> {
          let linfo = ladata.data;
          if(linfo.error){
            throw new Error(linfo.error);
          }
         linfo.passid = this.checkcode;
         this.pushSuccesfulscann(linfo);
          this.$f7.dialog.close();
          this.checkcode='';
         
        }).catch((error)=> {
          this.$f7.dialog.close();
          console.log('error.', error);
          this.$f7.dialog.alert(error.message,error.code);
        });
    }
  }
}
</script>
<style>
</style>